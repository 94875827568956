<form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal dkuform-modal-horizontal--wizard">
    <div class="control-group card-editor__label">
        <label class="control-label">
            X Variable
        </label>
        <div class="controls">
            <single-variable-selector [variables]="allVariables$ | async" formControlName="xColumn"></single-variable-selector>
        </div>
    </div>
    <div class="control-group card-editor__label">
        <label class="control-label">
            Y Variable
        </label>
        <div class="controls">
            <single-variable-selector [variables]="allVariables$ | async" formControlName="yColumn"></single-variable-selector>
        </div>
    </div>
    <div class="card-editor__rows" formArrayName="curves">
        <ng-container *ngFor="let curve of curveControls; let i = index">
            <div class="card-editor__row" [formGroupName]="i">
                <div class="control-group">
                    <label class="control-label">
                        Curve Type
                    </label>
                    <div class="controls">
                        <dku-bs-select-form-control [list]="fitCurves" formControlName="type"  ngOptions="v.value as v.name for v in list"></dku-bs-select-form-control>
                    </div>
                </div>
                <div class="control-group" *ngIf="curve.controls.degree.enabled">
                    <label class="control-label">
                        Degree
                    </label>
                    <div class="controls">
                        <input type="number" formControlName="degree">
                    </div>
                </div>
                <a (click)="remove(i)" class="card-editor__delete">
                    <i class="icon-trash"></i>
                </a>
            </div>
        </ng-container>
        <button (click)="add()" class="btn btn--secondary">+ Add a curve</button>
    </div>
</form>