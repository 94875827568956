import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { KsTest2SampCard } from 'src/generated-sources';
import { EChartOption } from 'echarts';
import _ from 'lodash';
import { CardAction } from '@features/eda/worksheet/cards/events';
import { ColorsService } from '@shared/graphics/colors.service';

@Component({
    selector: 'ks-test-2samp-card-body',
    templateUrl: './ks-test-2samp-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './ks-test-2samp-card-body.component.less'
    ]
})
export class KsTest2SampCardBodyComponent implements OnChanges {
    @Input() results: KsTest2SampCard.KsTest2SampCardResult;
    @Input() params: KsTest2SampCard;
    @Output() action = new EventEmitter<CardAction>();

    cdfChartOptions: EChartOption;

    constructor(public colorsService: ColorsService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.cdfChartOptions = this.results && this.buildChartOptions(this.results);
        }
    }

    get isRejected() {
        return this.results.pvalue <= (1 - this.params.confidenceLevel);
    }

    buildChartOptions(results: KsTest2SampCard.KsTest2SampCardResult): EChartOption {
        return {
            color: ['#3398DB'],
            animation: false,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'cross'
                }
            },
            grid: { left: 0, top: 10, right: 0, bottom: 0, containLabel: true },
            xAxis: [{
                type: 'value',
                scale: true,
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: { color: '#999999' }
            }],
            yAxis: {
                type: 'value',
                min: 0,
                max: 1,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { color: '#999999' }
            },
            series: [
                {
                    type: 'line',
                    symbol: 'none',
                    step: 'middle',
                    data: _.zip(results.cdf1.xvals, results.cdf1.probs),
                    itemStyle: {
                        normal: {
                            color: this.colorsService.getColorFromIndex(0)
                        }
                    }
                },
                {
                    type: 'line',
                    step: 'middle',
                    symbol: 'none',
                    data: _.zip(results.cdf2.xvals, results.cdf2.probs),
                    itemStyle: {
                        normal: {
                            color: this.colorsService.getColorFromIndex(1)
                        }
                    }
                }
            ]
        };
    }
}
