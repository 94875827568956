import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MoodTestNSampCard, OneWayANOVACard, MoodTest2SampCard, TTest2SampCard, BoxPlotFragment, AbstractNSampTestCard, isAbstract2SampTestCard } from 'src/generated-sources';
import { CardAction } from '@features/eda/worksheet/cards/events';
import { EChartOption } from 'echarts';
import _ from 'lodash';
import { encodeHTML } from 'entities';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';

type SupportedCard =
    MoodTestNSampCard
    | OneWayANOVACard
    | MoodTest2SampCard
    | TTest2SampCard;

type SupportedCardResult =
    MoodTestNSampCard.MoodTestNSampCardResult
    | OneWayANOVACard.OneWayANOVACardResult
    | MoodTest2SampCard.MoodTest2SampCardResult
    | TTest2SampCard.TTest2SampCardResult;

@Component({
    selector: 'generic-nsamp-test-card-body',
    templateUrl: './generic-nsamp-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './generic-nsamp-test-card-body.component.less'
    ]
})
export class GenericNSampTestCardBodyComponent implements OnChanges {
    @Input() results: SupportedCardResult;
    @Input() params: SupportedCard;
    @Output() action = new EventEmitter<CardAction>();

    boxPlotsOptions?: EChartOption[];
    globalBoxPlotOptions?: EChartOption;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.refreshBoxPlots();
        }
    }

    get isRejected() {
        return this.results.pvalue <= (1 - this.params.confidenceLevel);
    }

    get isMedianRelevant() {
        return this.locationParameterName === 'median';
    }

    get isMeanRelevant() {
        return this.locationParameterName === 'mean';
    }

    get locationParameterName() {
        switch (this.params.type) {
            case 'oneway_anova':
            case 'ttest_2samp':
                return 'mean';
            case 'mood_nsamp':
            case 'mood_test_2samp':
                return 'median';
        }
    }

    get hasTwoSamples() {
        return isAbstract2SampTestCard(this.params);
    }

    get testStatisticName() {
        switch (this.params.type) {
            case 'oneway_anova':
                return 'F-value';
            case 'mood_nsamp':
            case 'mood_test_2samp':
                return 'Pearson’s chi-square statistic';
            case 'ttest_2samp':
                return '𝘵-statistic';
        }
    }

    createBoxPlot(boxPlotData: AbstractNSampTestCard.GroupInfo): EChartOption {
        return {
            grid: { left: 0, top: 0, right: 0, bottom: 0 },
            yAxis: {
                type: 'category',
                show: false
            },
            animation: false,
            tooltip: {
                confine: true,
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                min: this.results.allGroups.boxPlot.min || 0,
                max: this.results.allGroups.boxPlot.max || 0,
                type: 'value',
                show: false
            },
            series: boxPlotData.boxPlot ? [
                {
                    name: 'boxplot',
                    type: 'boxplot',
                    tooltip: {
                        formatter: (param: { dataIndex: number, data: number[] }) => {
                            return encodeHTML(this.params.yColumn.name)
                                + ':<br>' + [
                                    '&bull; 1st percentile: ' + encodeHTML(smarterNumber(param.data[1])),
                                    '&bull; 1st quartile: ' + encodeHTML(smarterNumber(param.data[2])),
                                    '&bull; Median: ' + encodeHTML(smarterNumber(param.data[3])),
                                    '&bull; 3rd quartile: ' + encodeHTML(smarterNumber(param.data[4])),
                                    '&bull; 99th percentile: ' + encodeHTML(smarterNumber(param.data[5]))
                                ].join('<br/>');
                        }
                    },
                    data: [[
                        boxPlotData.boxPlot.pc01 || 0,
                        boxPlotData.boxPlot.pc25 || 0,
                        boxPlotData.boxPlot.median || 0,
                        boxPlotData.boxPlot.pc75 || 0,
                        boxPlotData.boxPlot.pc99 || 0
                    ]],
                    itemStyle: {
                        borderColor: '#888888',
                        borderWidth: 1
                    }
                }
            ] : []
        };
    }

    refreshBoxPlots() {
        this.boxPlotsOptions = this.results.groups.map((group) => this.createBoxPlot(group));
        this.globalBoxPlotOptions = this.createBoxPlot(this.results.allGroups);
    }
}
