<table>
    <tr>
        <td>
            <div>
                <table *ngIf="xLabels && yLabels && data" class="matrix-table"
                    (mouseleave)="mouseOutScale()"
                    [style.border]="tableBorder" #tableHeatmap
                >
                    <tbody>
                        <tr *ngFor="let yname of maskedYLabels; index as yindex; trackBy: trackByIndex">
                            <td (click)="sortForY(yindex)"
                                [style.border]="tableBorder"
                                class="ylabel-cell"
                                [qa]="{'eda-heatmap-row-label': {variable: yname }}"
                            >
                                <span class="ylabel" title="{{yname}}">{{yname}}</span>
                                <b *ngIf="showAscendingArrowY(yindex)" class="caret rotate-left align-caret"></b>
                                <b *ngIf="showDescendingArrowY(yindex)" class="caret rotate-right align-caret"></b>
                            </td>
                            <td *ngFor="let xname of maskedXLabels; index as xindex; trackBy: trackByIndex"
                                title="{{yname}} on {{xname}} : {{maskedDataOrWarning(xindex,yindex)}}"
                                [style.background-color]="maskedColors[xindex][yindex]"
                                [style.color]="textColor(maskedData[xindex][yindex])"
                                [style.border]="tableBorder"
                                [class.unavailable-result]="null == maskedData[xindex][yindex]"
                                (mouseenter)="mouseOverValue(maskedData[xindex][yindex])"
                                class="matrix-value"
                                >
                                {{ heatmapParams.showValues ? displayValue(maskedData[xindex][yindex]) : '' }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td *ngFor="let xname of maskedXLabels; index as xindex; trackBy: trackByIndex" (click)="sortForX(xindex)"
                                [style.border]="tableBorder"
                                [qa]="{'eda-heatmap-column-label': {variable: xname }}"
                            >
                                <div class="pull-right">
                                    <b *ngIf="showAscendingArrowX(xindex)" class="caret vertical-mirror align-caret"></b>
                                    <b *ngIf="showDescendingArrowX(xindex)" class="caret align-caret"></b>
                                </div>
                                <span [title]="xname"  class="xlabel">{{xname}}</span>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </td>
        <td>
            <canvas [hidden]="!heatmapParams.showColors"
                class="scale-canvas" #scaleCanvas
                (mousemove)="mouseOverScale($event)"
                (mouseout)="mouseOutScale()"></canvas>
        </td>
    </tr>
</table>
