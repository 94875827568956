export namespace ITaggingService {
    /**
     * Generated from com.dataiku.dip.server.services.ITaggingService$TaggableType
     */
    export enum TaggableType {
        PROJECT = 'PROJECT',
        DATASET = 'DATASET',
        RECIPE = 'RECIPE',
        SQL_NOTEBOOK = 'SQL_NOTEBOOK',
        JUPYTER_NOTEBOOK = 'JUPYTER_NOTEBOOK',
        ANALYSIS = 'ANALYSIS',
        SAVED_MODEL = 'SAVED_MODEL',
        INSIGHT = 'INSIGHT',
        MANAGED_FOLDER = 'MANAGED_FOLDER',
        LAMBDA_SERVICE = 'LAMBDA_SERVICE',
        SCENARIO = 'SCENARIO',
        DASHBOARD = 'DASHBOARD',
        WEB_APP = 'WEB_APP',
        REPORT = 'REPORT',
        ARTICLE = 'ARTICLE',
        STATISTICS_WORKSHEET = 'STATISTICS_WORKSHEET',
        API_DEPLOYER_INFRA = 'API_DEPLOYER_INFRA',
        API_DEPLOYER_SERVICE = 'API_DEPLOYER_SERVICE',
        API_DEPLOYER_DEPLOYMENT = 'API_DEPLOYER_DEPLOYMENT'
    }
}