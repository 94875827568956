import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxEchartsModule } from 'ngx-echarts';
import { InViewportModule } from 'ng-in-viewport';

import { LazyEchartComponent } from './components/lazy-echart/lazy-echart.component';
import { LazyRenderComponent } from './components/lazy-render/lazy-render.component';
import { DragScrollComponent } from './components/drag-scroll/drag-scroll.component';
import { WindowService } from './window.service';

@NgModule({
    imports: [
        CommonModule,
        NgxEchartsModule,
        InViewportModule,
        ScrollingModule,
    ],
    entryComponents: [],
    providers: [
        WindowService,
    ],
    bootstrap: [],
    declarations: [
        LazyEchartComponent,
        LazyRenderComponent,
        DragScrollComponent,
    ],
    exports: [
        LazyEchartComponent,
        LazyRenderComponent,
        DragScrollComponent,
    ],
})
export class DkuFrontendCoreModule { }
