import { init } from 'echarts';

/**
 * Monkey-patch Echarts/zrender to avoid excessive resource consumption
 *
 * Before this patch:
 * - Zrender internally starts a requestAnimationFrame() loop to handle redraw/animations
 * - This loop keeps running as long as the chart is displayed in the page, even if there is no animation...
 * - In current browsers (checked on Chrome 76 and Firefox 68), firing a new animation frame has some overhead
 *   (because it triggers "Update Layer Tree", "Composite Layer", ...) even if the handler is a no-op
 *
 * => CPU/GPU consumption is very high because the page is constantly being redrawn
 *
 * With this patch:
 * - The requestAnimationFrame() loop stops itself if it has nothing to do anymore
 *
 * => Browser goes idle when there is nothing to redraw
 *
 */

export function patchZRender() {
    // Create a fake echarts instance (only way to access the prototypes)
    const echartsInstance = init(document.createElement('div'));

    // Get corresponding zrender instance
    const zrenderInstance = (echartsInstance as any).getZr();

    // Patch zrender's Animation.js file so that requestAnimationFrame() loop is:
    // - Stopped whenever there are no clips in animation manager (Animation.js)
    // - Restarted whenever something is likely to change this may have changed
    const animationClass = zrenderInstance.animation.constructor.prototype;

    patch(animationClass, 'addClip', function(unpatched) {
        unpatched();
        this.start(); // Automatically restart requestAnimationFrame() loop
    });

    patch(animationClass, '_update', function(unpatched) {
        if (this.isFinished()) {
            this.stop(); // Automatically stop requestAnimationFrame() loop
        }
        unpatched();
    });

    patch(animationClass, '_startLoop', function(unpatched) {
        if (this._running) {
            return; // Make sure requestAnimationFrame() loop isn't already started
        }
        unpatched();
    });

    // Patch ZRender main class to restart the requestAnimationFrame() loop in restart in some specific cases
    // such as hover, changed configuration, etc.
    const zrenderClass = zrenderInstance.constructor.prototype;
    const methodNames = [
        'add', 'remove', 'configLayer', 'setBackgroundColor', 'refreshImmediately',
        'refresh', 'refreshHover', 'refreshHoverImmediately'
    ];
    for (const methodName of methodNames) {
        patch(zrenderClass, methodName, function(unpatched) {
            unpatched();
            this.animation.start(); // Restart the loop
        });
    }

    // Not needed anymore
    echartsInstance.dispose();

    console.log('Zrender patched!');
}


function patch(proto: any, methodName: string, wrapper: (this: any, unpatched: () => any) => any) {
    const existingMethod = proto[methodName];
    if (!existingMethod) {
        throw new Error('Couldn\'t patch method: ' + methodName);
    }

    // tslint:disable-next-line: only-arrow-functions
    proto[methodName] = function() {
        const args = arguments;
        return wrapper.bind(this)(() => existingMethod.apply(this, args));
    };
}
