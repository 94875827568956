import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnDestroy, OnChanges } from '@angular/core';
import { ScatterPlotCard } from 'src/generated-sources';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'scatter-plot-card-config',
    templateUrl: './scatter-plot-card-config.component.html',
    styleUrls: ['./scatter-plot-card-config.component.less']
})
export class ScatterPlotCardConfigComponent implements OnDestroy, OnChanges {
    @Input() params: ScatterPlotCard;
    @Output() paramsChange = new EventEmitter<ScatterPlotCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    configForm = new FormGroup({
        symbolSize: new FormControl(null, [
            Validators.min(1),
            Validators.max(50),
            Validators.required
        ])
    });

    constructor() {
        this.configForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(formValue => this.configForm.valid && this.paramsChange.emit({
                ...this.params,
                symbolSize: formValue.symbolSize
            }));

        this.configForm.statusChanges.pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnChanges() {
        this.configForm.patchValue({
            symbolSize: this.params.symbolSize
        });
    }

    ngOnDestroy() {
    }
}
