import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommaSeparatedViewDirective } from '@shared/directives/comma-separated-view/comma-separated-view.directive';
import { ForceIntegerDirective } from '@shared/directives/force-integer/force-integer.directive';
import { QaSelectorDirective } from '@shared/directives/qa-selector/qa-selector.directive';
import { NgxEchartsModule } from 'ngx-echarts';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { ClickStopDirective } from '@shared/directives/click-stop/click-stop.directive';
import { SimpleNumberListComponent } from './simple-number-list/simple-number-list.component';
import { ContainerSelectionFormComponent } from './container-selection-form/container-selection-form.component';
import { DoclinkWrapperComponent } from './doclink-wrapper/doclink-wrapper.component';
import { DatasetErrorCtaComponent } from './dataset-error-cta/dataset-error-cta.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        NgxEchartsModule,
        DataikuAPIModule
    ],
    declarations: [
        HeatmapComponent,
        CommaSeparatedViewDirective,
        ClickStopDirective,
        ForceIntegerDirective,
        QaSelectorDirective,
        SimpleNumberListComponent,
        ContainerSelectionFormComponent,
        DoclinkWrapperComponent,
        DatasetErrorCtaComponent
    ],
    exports: [
        HeatmapComponent,
        CommaSeparatedViewDirective,
        ClickStopDirective,
        ForceIntegerDirective,
        QaSelectorDirective,
        SimpleNumberListComponent,
        ContainerSelectionFormComponent,
        DoclinkWrapperComponent,
        DatasetErrorCtaComponent
    ],
    entryComponents: [
    ]
})
export class ComponentsModule {
}
