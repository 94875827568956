<div class="list-box__main vertical-flex">
    <ul class="list-box__items flex" cdkDropList [cdkDropListDisabled]="!dragDropable"
        [cdkDropListSortingDisabled]="!sortDraggable" (cdkDropListDropped)="drop($event)"
        [class.drag-drop-container-empty]="!list || !list.length"
    >
        <ng-container *ngFor="let item of list">
            <li class="list-box__item" *ngIf="item.name !== undefined" [qa]="{'list-box-item': {variable: item.name}}"
                [ngClass]="{ 'list-box__item--selected': item.selected, 'list-box__item--disabled': item.disabled, 'list-box__item--hide-on-drop': hideItemsOnDrop}"
                cdkDrag [cdkDragData]="item" [cdkDragDisabled]="item.disabled" (cdkDragStarted)="dragStarted($event)"
            >
                <a class="list-box__link" (click)="selectItem($event, item)">
                    <i class="list-box__icon" [ngClass]="{'icon-font': item.type === Variable.Type.CATEGORICAL, 'icon-number': item.type === Variable.Type.CONTINUOUS }"
                    ></i>
                    {{ item.name }}
                </a>
                <a class="list-box__delete" (click)="removeItem(item);" [qa]="{'list-box-item': {variable: item.name, action: 'delete'}}">
                    <i class="icon-trash"></i>
                </a>
                <div class="drag-drop-placeholder" *cdkDragPlaceholder><i class="list-box__icon" [ngClass]="{ 'icon-font': item.type === Variable.Type.CATEGORICAL, 'icon-number': item.type === Variable.Type.CONTINUOUS }" ></i>Variable(s)</div>
                <div class="drag-drop-preview" *cdkDragPreview><i class="list-box__icon" [ngClass]="{ 'icon-font': item.type === Variable.Type.CATEGORICAL, 'icon-number': item.type === Variable.Type.CONTINUOUS }"></i>
                        Variable(s)
                </div>
            </li>
        </ng-container>
        <div class="drag-drop-placeholder-text" *ngIf="placeHolderText && (!list || !list.length)">
            {{placeHolderText}}
        </div>
    </ul>
</div>
