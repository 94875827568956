<form
    [formGroup]="configForm"
    id="config-form"
    class="dkuform-modal-vertical"
>
    <div class="control-group">
        <label class="control-label">Symbol size</label>
        <div class="controls">
            <label>
                <input
                    type="number"
                    formControlName="symbolSize"
                >
            </label>
        </div>
    </div>
</form>
