import {Card, _Card} from './card';
import {CorrelationMatrixCard} from './correlation-matrix-card';
import {PCACard} from './pcacard';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.MultivariateCard
 */
export interface _MultivariateCard extends _Card {
    columns: Variable[];
}

export type MultivariateCard = PCACard | CorrelationMatrixCard;

export function isMultivariateCard(toTest: Card): toTest is MultivariateCard { return ["pca" , "correlation_matrix"].includes(toTest.type); }