import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Card } from 'src/generated-sources';

@Component({
    selector: 'card-editor',
    templateUrl: './card-editor.component.html',
    styleUrls: [
        '../../../shared-styles/card-wizard.less',
        './card-editor.component.less'
    ]
})
export class CardEditorComponent implements OnInit, OnDestroy {
    @Input() params: Card;
    @Output() paramsChange = new EventEmitter<Card>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    className = '';

    constructor() {

    }

    ngOnInit() {
        const typeClass = 'card-wizard__body--' + this.params.type;
        this.className = typeClass + ' card-wizard__body--' + this.getEditorWidth();
    }

    ngOnDestroy() {
    }

    getEditorWidth() {
        if (this.params.type !== 'univariate_header' &&
            this.params.type !== 'bivariate_header' &&
            this.params.type !== 'correlation_matrix') {
            return 'narrow';
        }

        return 'wide';
    }
}
