// @ts-ignore
import IntervalScale from 'echarts/src/scale/Interval';


export function getNumericalChartAxisTicks(splitXAxis: number, minVal: number, maxVal: number): number[] {
    const interval = new IntervalScale();
    interval.setExtent(minVal, maxVal);
    interval.niceExtent({
        fixMin: true,
        fixMax: true,
        splitXAxis
    });
    return interval.getTicks();
}