import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AbstractPairwiseTestCard, PValueAdjustmentMethod, Variable } from 'src/generated-sources';
import { Observable } from 'rxjs';
import { CardWizardVariable } from '@features/eda/card-models';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { toggleFormControl } from '@utils/toggle-form-control';

@UntilDestroy()
@Component({
    selector: 'abstract-pairwise-test-card-config',
    templateUrl: './abstract-pairwise-test-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './abstract-pairwise-test-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractPairwiseTestCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: AbstractPairwiseTestCard;
    @Input() variables: Variable[];
    @Output() paramsChange = new EventEmitter<AbstractPairwiseTestCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    adjustmentMethods = [{
        name: 'None',
        value: PValueAdjustmentMethod.NONE
    }, {
        name: 'Bonferroni',
        value: PValueAdjustmentMethod.BONFERRONI
    }, {
        name: 'Holm-Bonferroni',
        value: PValueAdjustmentMethod.HOLM_BONFERRONI
    }];

    testVariables$: Observable<CardWizardVariable[]>;
    groupVariables$: Observable<CardWizardVariable[]>;

    private groupingMethod = this.fb.control(null, [Validators.required]);

    configForm = this.fb.group({
        xColumn: this.fb.control(null, [Validators.required]),
        yColumn: this.fb.control(null, [Validators.required]),
        groupingMethod: this.groupingMethod,
        yValues: toggleFormControl(this.fb.control({ value: null, disabled: true }, [Validators.required, Validators.minLength(1)]), this.groupingMethod, 'manual'),
        maxGroups: toggleFormControl(this.fb.control({ value: null, disabled: true }, [Validators.required, Validators.min(2)]), this.groupingMethod, 'auto'),
        adjustmentMethod: this.fb.control(null, [Validators.required])
    });

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => {
                const params = {
                    ...this.params,
                    xColumn: formValue.xColumn,
                    yColumn: formValue.yColumn,
                    adjustmentMethod: formValue.adjustmentMethod
                };

                if (formValue.groupingMethod === 'auto') {
                    params.maxGroups = formValue.maxGroups;
                    delete params.yValues;
                } else {
                    params.yValues = formValue.yValues;
                    delete params.maxGroups;
                }

                this.paramsChange.emit(params);
            });

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        this.testVariables$ = this.cardWizardService.availableVariables(this.params.type, { isTestVariable: true });
        this.groupVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                xColumn: this.params.xColumn,
                yColumn: this.params.yColumn,
                groupingMethod: this.params.yValues ? 'manual' : 'auto',
                yValues: this.params.yValues,
                maxGroups: this.params.maxGroups,
                adjustmentMethod: this.params.adjustmentMethod
            });
        }
    }

    ngOnDestroy() {

    }
}
