<span *ngIf="readOnly; else splitByEditable">
    <span *ngIf="splitBy?.groupingColumn">split by </span>
    <span [ngClass]="{'card__header-split--active': !!splitBy?.groupingColumn }"
        class="split-by__text">
        {{ splitBy?.groupingColumn ? splitBy?.groupingColumn.name : '' }}
    </span>
</span>
<ng-template #splitByEditable>
    <span [dkuPopoverTrigger]="splitByMenu"
        [qa]="'eda-split-by-menu'"
        [wt1Click]="'worksheets_split-button'"
        >
        <span *ngIf="splitBy?.groupingColumn">split by </span>
        <span class="split-by__text" [ngClass]="{'card__header-split--active': !!splitBy?.groupingColumn }">
            {{ splitBy?.groupingColumn ? splitBy?.groupingColumn.name : 'No split' }}
        </span>
        <i class="icon-sort-down split-by__arrow"></i>
    </span>
    <dku-popover #splitByMenu>
        <div class="dkuform-horizontal" [qa]="'eda-split-by-popover'">
            <form
                [formGroup]="form"
                (ngSubmit)="save()"
            >
                <div class="control-group">
                    <label class="control-label control-dropdown-label">Split by</label>
                    <div class="controls">
                        <single-variable-selector [variables]="variables$ | async" [noVariableLabel]="'No split'" [noneSelectedText]="'No split'" formControlName="selectedVariable"></single-variable-selector>
                    </div>
                </div>
                <binning-config formControlName="binningConfig" [isCategorical]="isCategorical"
                    *ngIf="form.controls.selectedVariable.value">
                </binning-config>
                <div class="control-group" *ngIf="form.controls.selectedVariable.value && allowGroupAll && form.controls.groupWithAll.enabled">
                    <label>
                        <input
                            formControlName="groupWithAll"
                            class="form__checkbox"
                            type="checkbox"
                        >
                        Show additional group for all values
                    </label>
                </div>
                <div class="split-by__button pull-right">
                    <button
                        class="btn btn--primary"
                        [qa]="'eda-split-by-save-button'"
                        [disabled]="form.invalid"
                        (click)="save()"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    </dku-popover>
</ng-template>
