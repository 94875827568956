<form [formGroup]="configForm" class="dkuform-modal-horizontal">
    <div class="control-group">
        <label class="control-label">
            Test Variable
        </label>
        <single-variable-selector [variables]="testVariables$ | async" formControlName="xColumn" [qa]="'two-sample-test-variable'"></single-variable-selector>
    </div>
    <div class="control-group">
        <label class="control-label">    
            Grouping Variable
        </label>
        <single-variable-selector [variables]="groupVariables$ | async" formControlName="yColumn" [qa]="'two-sample-test-grouping'"></single-variable-selector>
    </div>
    <ng-container *ngIf="configForm.get('yColumn')!.value && configForm.get('yColumn')!.value.name">
        <p class="card-editor__description">Enter values of <span class="card-editor__strong">{{ configForm.get('yColumn')!.value.name }}</span> to form the two populations below:</p>
        <div class="horizontal-flex">
            <div class="card-editor__column card-editor__column--group" [qa]="'two-sample-test-population-1'">
                <div class="control-group">
                    <label class="control-label">
                        Population 1
                    </label>
                    <basic-list-form [autocompleteSuggestions]="suggestedYValues$ | async"
                        formControlName="value1" [showButton]="false"></basic-list-form>
                </div>
            </div>
            <div class="card-editor__column card-editor__column--group" [qa]="'two-sample-test-population-2'">
                <div class="control-group">
                    <label class="control-label">
                        Population 2
                    </label>
                    <basic-list-form [autocompleteSuggestions]="suggestedYValues$ | async"
                        formControlName="value2" [showButton]="false"></basic-list-form>
                </div>
            </div>
        </div>
    </ng-container>
    <api-error-alert
        [apiError]="error"
        (resetError)="pushError(null)"
        [closable]="true"
    ></api-error-alert>
</form>