import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { realAny, fairAny } from 'dku-frontend-core';

declare let $: fairAny;

export enum ModalShape {
    NARROW = 'dku-modal-panel-narrow',
    WIDE = 'dku-modal-panel-wide',
    NONE = 'dku-modal-panel-none'
}

@Injectable({
    providedIn: 'root'
})
export class ModalsService {
    /*
    We use modals provided by material design,
    it handle the creation of the DOM objects, close on escape or outside of modal
    it keeps track of open modals to clear them so that they cannot persist after a transition
    */
    constructor(private dialogs: MatDialog) {
    }

    open(component: any, data?: object, modalShape = ModalShape.NARROW, parent?: ViewContainerRef, options?: MatDialogConfig) {
        const config = this.getBaseConfig(modalShape, parent, options);
        config.data = data;
        const dialogRef = this.dialogs.open(component, config);
        this.setupFocus(dialogRef);

        return new Promise<any>((resolve, reject) => {
            return dialogRef.afterClosed().subscribe((answer) => {
                if (answer) {
                    resolve(answer);
                } else {
                    reject();
                }
            });
        });
    }

    private setupFocus(dialogRef: MatDialogRef<realAny>) {
        dialogRef.afterOpened().subscribe(() => {
            const container = $('.mat-dialog-container');
            if (container.find('[autofocus]').length) {
                setTimeout(() => container.find('[autofocus]').first().focus(), 0);
            } else if (container.find('input').length) {
                setTimeout(() => container.find('input').first().focus(), 0);
            }
            //TODO prevent enter key action on all btn-danger
        });
    }

    private getBaseConfig(modalShape: ModalShape, parent?: ViewContainerRef, options: MatDialogConfig = {}) {
        const baseConfig = new MatDialogConfig();
        baseConfig.closeOnNavigation = true;
        baseConfig.panelClass = modalShape;
        baseConfig.viewContainerRef = parent;
        Object.assign(baseConfig, options);

        return baseConfig;
    }

}