<h4 class="column-title" [title]="(response ? response.name + ' by ' : '') + column.name + (splitBy ? ' split by ' + splitBy.groupingColumn.name : '')" [class.column-title--bivariate]="response" [class.column-title--split]="splitBy" [class.column-title--collapsed]="collapsed">
    <i
        *ngIf="showCollapsingControls"
        class="icon-chevron-right chevron"
        [class.chevron--collapsed]="collapsed"
    ></i>
    <ng-container *ngIf="response">
        <span
            [class.categorical]="response.type === Variable.Type.CATEGORICAL"
            [class.continuous]="response.type === Variable.Type.CONTINUOUS"
        >
            {{ response.name }}
        </span>
        <span class="text-weak">by</span>
    </ng-container>
    <span
        [class.categorical]="column.type === Variable.Type.CATEGORICAL"
        [class.continuous]="column.type === Variable.Type.CONTINUOUS"
    >
        {{ column.name }}
    </span>
    <span *ngIf="splitBy">
        <span class="text-weak">split by&nbsp;</span>
        <span *ngIf="splitBy">{{ splitBy.groupingColumn.name }}</span>
    </span>
</h4>
