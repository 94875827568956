import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BivariateFrequencyTableCard } from 'src/generated-sources';


@Component({
    selector: 'bivariate-frequency-table-card-body',
    templateUrl: './bivariate-frequency-table-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/fit-table.less',
        './bivariate-frequency-table-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BivariateFrequencyTableCardBodyComponent {
    @Input() params: BivariateFrequencyTableCard;
    @Input() results: BivariateFrequencyTableCard.BivariateFrequencyTableCardResult;
    @Input() isTopLevel = false;

    trackByIndex(index: number) {
        return index;
    }
}
