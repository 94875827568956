import { Component, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { PairwiseTTestCard, PairwiseMoodTestCard } from 'src/generated-sources';
import { ChangeDetectionStrategy } from '@angular/core';
import { CardAction } from '@features/eda/worksheet/cards/events';
import { EChartOption } from 'echarts';
import _ from 'lodash';
import { encodeHTML } from 'entities';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';

type SupportedCard =
    PairwiseTTestCard
    | PairwiseMoodTestCard;

type SupportedCardResult =
    PairwiseTTestCard.PairwiseTTestCardResult
    | PairwiseMoodTestCard.PairwiseMoodTestCardResult;

@Component({
    selector: 'generic-pairwise-test-card-body',
    templateUrl: './generic-pairwise-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './generic-pairwise-test-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GenericPairwiseTestCardBodyComponent implements OnChanges {
    @Input() results: SupportedCardResult;
    @Input() params: SupportedCard;
    @Output() action = new EventEmitter<CardAction>();

    boxPlotsOptions?: EChartOption[];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.refreshBoxPlots();
        }
    }

    get locationParameterName() {
        switch (this.params.type) {
            case 'pairwise_ttest':
                return 'mean';
            case 'pairwise_mood':
                return 'median';
        }
    }

    refreshBoxPlots() {
        const boxPlots = this.results.groups
            .filter(group => group.boxPlot)
            .map(group => group.boxPlot);
        const minVal = _.min(boxPlots.map(boxPlot => boxPlot.min)) || 0;
        const maxVal = _.max(boxPlots.map(boxPlot => boxPlot.max)) || 0;

        this.boxPlotsOptions = this.results.groups.map((group) => ({
            grid: { left: 0, top: 0, right: 0, bottom: 0 },
            yAxis: {
                type: 'category',
                show: false
            },
            animation: false,
            tooltip: {
                confine: true,
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                min: minVal,
                max: maxVal,
                type: 'value',
                show: false
            },
            series: group.boxPlot ? [
                {
                    name: 'boxplot',
                    type: 'boxplot',
                    tooltip: {
                        formatter: (param: { dataIndex: number, data: number[] }) => {
                            return encodeHTML(this.params.yColumn.name)
                                + ':<br>' + [
                                    '&bull; 1st percentile: ' + encodeHTML(smarterNumber(param.data[1])),
                                    '&bull; 1st quartile: ' + encodeHTML(smarterNumber(param.data[2])),
                                    '&bull; Median: ' + encodeHTML(smarterNumber(param.data[3])),
                                    '&bull; 3rd quartile: ' + encodeHTML(smarterNumber(param.data[4])),
                                    '&bull; 99th percentile: ' + encodeHTML(smarterNumber(param.data[5]))
                                ].join('<br/>');
                        }
                    },
                    data: [[
                        group.boxPlot.pc01 || 0,
                        group.boxPlot.pc25 || 0,
                        group.boxPlot.median || 0,
                        group.boxPlot.pc75 || 0,
                        group.boxPlot.pc99 || 0
                    ]],
                    itemStyle: {
                        borderColor: '#888888',
                        borderWidth: 1
                    }
                }
            ] : []
        }));
    }

    getFlattenedIndex(colIdx: number, rowIdx: number) {
        const nbGroups = this.results.groups.length;
        return (nbGroups * (nbGroups - 1) / 2)
            - (nbGroups - colIdx) * ((nbGroups - colIdx) - 1) / 2
            + rowIdx - colIdx - 1;
    }
}
