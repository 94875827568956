<lazy-render
    [trackData]="options"
    [visibleTemplate]="visible"
    [passThrough]="passThrough"
    [invisibleTemplate]="invisible"
>
    <ng-template #visible>
        <div
            echarts
            (chartClick)="chartClick.emit($event)"
            *ngIf="options"
            [options]="options"
        ></div>
    </ng-template>
    <ng-template #invisible>
        <div>Loading chart...</div>
    </ng-template>
</lazy-render>
