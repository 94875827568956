<form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal dkuform-modal-horizontal--wizard">
    <div class="control-group card-editor__label">
        <label class="control-label">
            Variable
        </label>
        <div class="controls">
            <single-variable-selector [variables]="allVariables$ | async" formControlName="column"></single-variable-selector>
        </div>
    </div>
    <div class="card-editor__rows" formArrayName="distributions">
        <ng-container *ngFor="let distribution of distributionControls; let i = index">
            <div class="card-editor__row" [formGroupName]="i">
                <ng-container formGroupName="distribution">
                    <div class="control-group">
                        <label class="control-label">
                            Distribution
                        </label>
                        <div class="controls">
                            <dku-bs-select-form-control [list]="fitDistributions" formControlName="type" ngOptions="v.value as v.name for v in list"></dku-bs-select-form-control>
                        </div>
                    </div>
                    <div class="control-group" *ngIf="distribution.controls.distribution.controls.nbComponents.enabled">
                        <label class="control-label">
                            Number of components
                        </label>
                        <div class="controls">
                            <input type="number" formControlName="nbComponents">
                        </div>
                    </div>
                    <a (click)="remove(i)" class="card-editor__delete">
                        <i class="icon-trash"></i>
                    </a>
                </ng-container>
            </div>
        </ng-container>
        <button (click)="add()" class="btn btn--secondary">+ Add a distribution</button>
    </div>
</form>