import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { BivariateHistogramCard } from 'src/generated-sources';
import { EChartOption } from 'echarts';
import { CardActionType, CardAction } from '@features/eda/worksheet/cards/events';
import { ColorsService } from '@shared/graphics/colors.service';
import { encodeHTML } from 'entities';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import d3 from 'd3';
import { PatternsService } from '@shared/graphics/patterns.service';

@Component({
    selector: 'bivariate-histogram-card-body',
    templateUrl: './bivariate-histogram-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './bivariate-histogram-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BivariateHistogramCardBodyComponent implements OnChanges {
    @Input() results: BivariateHistogramCard.BivariateHistogramCardResult;
    @Input() params: BivariateHistogramCard;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    chartOptions: EChartOption;

    constructor(
        private colorsService: ColorsService,
        private patternsService: PatternsService) {
    }

    chartClicked(event: any) {
        if (event.componentType === 'series'
            && event.componentSubType === 'bar') {
            const xIndex: number = event.dataIndex;
            let yIndex;
            if (this.results.histogram.highlightedCounts) {
                // Because we have 2 series per bar (regular+highlight)
                yIndex = Math.floor(event.seriesIndex / 2);
            } else {
                yIndex = event.seriesIndex;
            }
            let xFilter = this.results.histogram.xBins[xIndex];
            xFilter = { ...xFilter, name: this.params.xColumn.name + ': ' + filterName(xFilter) };
            let yFilter = this.results.histogram.yBins[yIndex];
            yFilter = { ...yFilter, name: this.params.yColumn.name + ': ' + filterName(yFilter) };

            this.action.emit({
                type: CardActionType.HIGHLIGHT,
                filter: { type: 'and', filters: [xFilter, yFilter] }
            });
        }
    }

    updateNBins(value: number) {
        const newParams = {
            ...this.params,
            maxValues: value
        };

        this.action.emit({ type: CardActionType.UPDATE, newParams });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.chartOptions = this.results && this.buildChartOptions(this.results);
        }
    }

    buildChartOptions(results: BivariateHistogramCard.BivariateHistogramCardResult): EChartOption {
        const xBinLabels: string[] = [];
        const histogramData = results.histogram;
        const xBins = histogramData.xBins;
        const yBins = histogramData.yBins;
        const xBinCount = xBins.length;
        const yBinCount = yBins.length;
        const series: EChartOption.Series[] = [];
        let maxCount = 0;

        for (let j = 0; j < yBinCount; j++) {
            const name = filterName(yBins[j]);
            const regularData: number[] = [];
            const highlightData: number[] = [];

            const tooltipFormatter = (params: any) => {
                const xLabel = filterName(xBins[params.dataIndex]);
                const yLabel = filterName(yBins[j]);
                const flatIndex = params.dataIndex * yBinCount + j;

                let tooltip = `
                    ${encodeHTML(this.params.xColumn.name)}:
                    <b>${encodeHTML(xLabel)}</b>
                    <br>
                    ${encodeHTML(this.params.yColumn.name)}:
                    <b>${encodeHTML(yLabel)}</b>
                    <br>
                    Count:
                    <b>${encodeHTML('' + histogramData.counts[flatIndex])}</b>
                `;

                if (histogramData.highlightedCounts) {
                    tooltip += `<br>Selected count:
                        <b>
                            ${encodeHTML('' + histogramData.highlightedCounts[flatIndex])}
                        </b>
                    `;
                }
                return tooltip;
            };

            for (let i = 0; i < xBinCount; i++) {
                if (j === 0) {
                    xBinLabels.push(filterName(xBins[i]));
                }
                const count = histogramData.counts[i * yBinCount + j];
                maxCount = Math.max(count, maxCount);

                if (histogramData.highlightedCounts) {
                    const highlightCount = histogramData.highlightedCounts[i * yBinCount + j];
                    regularData.push(count - highlightCount);
                    highlightData.push(highlightCount);
                } else {
                    regularData.push(count);
                }
            }
            const itemColor = this.colorsService.getColorForVariable(name);

            if (histogramData.highlightedCounts) {
                const borderColor = d3.rgb(itemColor).darker(2).toString();
                const stripedPattern = this.patternsService.getStripePattern(borderColor, itemColor);
                const highlightedItemStyle = {
                    barBorderColor: borderColor,
                    barBorderWidth: 1,
                    color: (stripedPattern as any) // Bad typings
                };
                series.push({
                    name,
                    stack: 's' + j,
                    type: 'bar',
                    emphasis: { itemStyle: highlightedItemStyle },
                    itemStyle: highlightedItemStyle,
                    data: highlightData,
                    tooltip: { formatter: tooltipFormatter }
                });
            }
            series.push({
                name,
                type: 'bar',
                stack: 's' + j,
                emphasis: { itemStyle: { color: itemColor } },
                itemStyle: { color: itemColor },
                data: regularData,
                tooltip: { formatter: tooltipFormatter }
            });
        }

        return {
            animation: false,
            tooltip: {
                confine: true,
                trigger: 'item',
                axisPointer: { type: 'none' }
            },
            grid: { left: 0, top: 10, right: 0, bottom: 0, containLabel: true },
            xAxis: [{
                type: 'category',
                data: xBinLabels,
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: {
                    color: '#999999',
                    rotate: 45,
                    formatter(value: string) {
                        const LABEL_LIMIT = 15;
                        return value.slice(0, LABEL_LIMIT) + (value.length > LABEL_LIMIT ? '...' : '');
                    }
                }
            }],
            yAxis: [{
                type: 'value',
                min: 0,
                max: maxCount,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { color: '#999999' }
            }],
            series
        };
    }

}
