<div class="modal-header {{modalClass}}" [ngClass]="{'has-border': hasBorder }">
    <div class="modal-totem" *ngIf="icon?.length" (click)="iconAction.emit()" [ngClass]="{ 'modal-totem--action': iconHasAction }">
        <i class="{{icon}}"></i>
    </div>
    <a class="modal-header-button"
        (click)="dialogRef.close()"
        aria-hidden="true"
        >
        &times;
    </a>
    <a class="modal-header-button see-through-button"
        aria-hidden="true"
        (mouseenter)="setSeeThrough(true)"
        (mouseleave)="setSeeThrough(false)"
        >
        &minus;
    </a>
    <h4 class="modal-title" [ngClass]="{'has-icon': icon?.length}">
        {{title}}
    </h4>
</div>
