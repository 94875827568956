import {ListMostFrequentValues} from './list-most-frequent-values';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.InteractiveQuery
 */
export interface _InteractiveQuery {}

export type InteractiveQuery = ListMostFrequentValues;

export namespace InteractiveQuery {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult
     */
    export interface _InteractiveQueryResult {}

    export type InteractiveQueryResult = ListMostFrequentValues.ListMostFrequentValuesResult;

    export function isInteractiveQueryResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is InteractiveQueryResult { return ["list_most_frequent_values"].includes(toTest.type); }
}