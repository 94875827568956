import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { ShapiroNormalityTestCard, IntervalFilter } from 'src/generated-sources';
import { EChartOption } from 'echarts';
import * as _ from 'lodash';

@Component({
    selector: 'shapiro-normality-test-card-body',
    templateUrl: './shapiro-normality-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './shapiro-normality-test-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShapiroNormalityTestCardBodyComponent implements OnChanges {
    @Input() params: ShapiroNormalityTestCard;
    @Input() results: ShapiroNormalityTestCard.ShapiroNormalityTestCardResult;
    densityChartOptions?: EChartOption;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.buildDensityChart();
        }
    }

    get isRejected() {
        return this.results.pvalue <= (1 - this.params.confidenceLevel);
    }

    buildDensityChart() {
        // Histogram
        const histogramSeriesData = [];
        const histogramData = this.results.histogram;
        const totalCount = _.chain(this.results.histogram.counts).sum().value();

        for (let i = 0; i < histogramData.bins.length; i++) {
            const bin = histogramData.bins[i] as IntervalFilter;
            const count = histogramData.counts[i] / (totalCount * (bin.right - bin.left));
            histogramSeriesData.push([bin.left, bin.right, count]);
        }

        let maxDensity = _.chain(histogramSeriesData).map(2).max().value();
        const minVal = _.chain(histogramSeriesData).map(0).min().value();
        const maxVal = _.chain(histogramSeriesData).map(1).max().value();

        const histogramSeries = {
            type: 'custom',
            renderItem: function (params: any, api: any) {
                const yValue = api.value(2);
                const start = api.coord([api.value(0), yValue]);
                const size = api.size([api.value(1) - api.value(0), yValue]);

                return {
                    type: 'rect',
                    shape: {
                        x: start[0],
                        y: start[1],
                        width: size[0],
                        height: size[1]
                    },
                    style: api.style(),
                    emphasisStyle: api.style()
                };
            },
            itemStyle: {
                normal: { color: '#c4dffe', borderColor: '#c4dffe', borderWidth: 1 }
            },
            data: histogramSeriesData
        } as EChartOption.Series // Cast required because renderItem() typing is not really usable

        // Density of distributions
        const pdfPlot = this.results.normalPdf;
        const seriesData = pdfPlot.xvals.map((x, idx) => [x, pdfPlot.probs[idx]]);
        maxDensity = Math.max(_.chain(seriesData).map(1).max().value(), maxDensity);
        const pdfSeries: EChartOption.Series = {
            type: 'line',
            yAxisIndex: 0,
            data: seriesData,
            symbol: 'none'
        };

        // Build Echart options
        this.densityChartOptions = {
            color: ['#3398DB'],
            animation: false,
            grid: { left: 0, top: 10, right: 0, bottom: 0, containLabel: true },
            xAxis: {
                type: 'value',
                min: minVal,
                max: maxVal,
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: { color: '#999999' }
            },
            yAxis: {
                type: 'value',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false },
                splitLine: { show: false },
                name: 'Density',
                min: 0,
                max: maxDensity
            },
            series: [pdfSeries, histogramSeries]
        };
    }
}
