<ul class="list-items" *ngIf="items && items.length">
    <li *ngFor="let item of items">
        <span class="list-item__text" title="{{ item === '' ? '(no value)' : item }}">
            <ng-container *ngIf="item === ''">(no value)</ng-container> {{ item }} 
        </span>
        <a class="pull-right" (click)="removeListItem(item)">
            <i class="icon-trash"></i>
        </a>
    </li>
</ul>
<div *ngIf="editMode" class="horizontal-flex">
    <mat-form-field>
        <input type="text"
            [(ngModel)]="listItem"
            (ngModelChange)="updateSuggestions()"
            (keydown.enter)="addListItem($event)"
            class="card-option__input card-option__input--with-btn"
            #addInput
            matInput
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let suggestion of filteredSuggestions" [value]="suggestion">
                {{ suggestion }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <button type="button" class="btn btn--primary" (click)="addListItem($event)">Add</button>
</div>
<button type="button" class="btn btn--secondary" (click)="activateGroup()" *ngIf="!editMode">+ Add group</button>