import { ShapiroNormalityTestCard, UnivariateHeaderCard, Variable } from 'src/generated-sources';
import { randomId } from '@utils/random';
import { DEFAULT_SPLIT_BY_SPEC } from '@utils/dss-defaults';

export function createVarianceComparisonCard(column: Variable, groupBy: Variable): UnivariateHeaderCard {
    return {
        id: randomId(),
        type: 'univariate_header',
        xColumns: [column],
        showSummary: true,
        showBoxPlot: false,
        showHistogram: false,
        showQuantile: false,
        showFrequencyTable: false,
        cards: [
            {
                id: randomId(),
                type: 'column_card',
                column,
                cards: [
                    {
                        id: randomId(),
                        type: 'univariate_summary',
                        column,
                        confidenceLevel: 0.95,
                        showConfidenceInterval: false,
                        compute: {
                            // Display variance
                            variance: true,
                            // Override default values to display variance only (a bit verbose...)
                            totalCount: false,
                            mean: false,
                            sum: false,
                            median: false,
                            std: false,
                            skewness: false,
                            kurtosis: false,
                            sem: false,
                            completeCount: false,
                            nbEmpty: false,
                            zeroCount: false,
                            zeroRatio: false,
                            nonZeroRatio: false,
                            iqr: false,
                            distinctCount: false,
                            nbNonEmpty: false,
                            min: false,
                            max: false,
                            range: false,
                            mode: false
                        }
                    }
                ]
            }
        ],
        splitBy: {
            ...DEFAULT_SPLIT_BY_SPEC,
            groupingColumn: groupBy
        }
    };
}

export function createShapiroCard(column: Variable, groupBy?: Variable): ShapiroNormalityTestCard {
    return {
        id: randomId(),
        type: 'shapiro',
        column,
        splitBy: groupBy ? { ...DEFAULT_SPLIT_BY_SPEC, groupingColumn: groupBy } : null,
        confidenceLevel: 0.95
    };
}
