import {BivariateBoxPlotCard} from './bivariate_header/bivariate-box-plot-card';
import {BivariateFrequencyTableCard} from './bivariate_header/bivariate-frequency-table-card';
import {BivariateHeaderCard} from './bivariate_header/bivariate-header-card';
import {BivariateHistogramCard} from './bivariate_header/bivariate-histogram-card';
import {BivariateSummaryStatsCard} from './bivariate_header/bivariate-summary-stats-card';
import {CategoricalHistogramCard} from './univariate_header/categorical-histogram-card';
import {Chi2IndTestCard} from './chi2-ind-test-card';
import {ColumnCard} from './common/column-card';
import {CorrelationMatrixCard} from './correlation-matrix-card';
import {EdaErrorCodes} from './../../eda-error-codes';
import {Fit2DDistributionCard} from './fit2-ddistribution-card';
import {FitCurveCard} from './fit-curve-card';
import {FitDistributionCard} from './fit-distribution-card';
import {GroupedCompiledCardWrapper} from './common/grouped-compiled-card-wrapper';
import {KsTest2SampCard} from './ks-test2-samp-card';
import {MoodTest2SampCard} from './mood-test2-samp-card';
import {MoodTestNSampCard} from './mood-test-nsamp-card';
import {MosaicPlotCard} from './bivariate_header/mosaic-plot-card';
import {NumericalHistogramCard} from './univariate_header/numerical-histogram-card';
import {OneWayANOVACard} from './one-way-anovacard';
import {PCACard} from './pcacard';
import {PairwiseMoodTestCard} from './pairwise-mood-test-card';
import {PairwiseTTestCard} from './pairwise-ttest-card';
import {QuantilesTableCard} from './univariate_header/quantiles-table-card';
import {ScatterPlotCard} from './bivariate_header/scatter-plot-card';
import {ShapiroNormalityTestCard} from './shapiro-normality-test-card';
import {SignTest1SampCard} from './sign-test1-samp-card';
import {TTest2SampCard} from './ttest2-samp-card';
import {TZTest1SampCard} from './tztest1-samp-card';
import {UnivariateFrequencyTableCard} from './univariate_header/univariate-frequency-table-card';
import {UnivariateHeaderCard} from './univariate_header/univariate-header-card';
import {UnivariateSummaryStatsCard} from './univariate_header/univariate-summary-stats-card';
import {WorksheetRootCard} from './worksheet-root-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.CardResult
 */
export interface _CardResult {
    computationCount: number;
    failedComputationsCount: number;
    notComputedCount: number;
    warnings?: string[] | null;
}

export type CardResult = CardResult.UnavailableCardResult | MosaicPlotCard.MosaicPlotCardResult | PairwiseMoodTestCard.PairwiseMoodTestCardResult | PairwiseTTestCard.PairwiseTTestCardResult | FitCurveCard.FitCurveCardResult | SignTest1SampCard.SignTest1SampCardResult | BivariateHeaderCard.BivariateHeaderCardResult | BivariateHistogramCard.BivariateHistogramCardResult | CategoricalHistogramCard.CategoricalHistogramCardResult | KsTest2SampCard.KsTest2SampCardResult | OneWayANOVACard.OneWayANOVACardResult | PCACard.PCACardResult | BivariateFrequencyTableCard.BivariateFrequencyTableCardResult | ScatterPlotCard.ScatterPlotCardResult | BivariateSummaryStatsCard.BivariateSummaryStatsCardResult | Fit2DDistributionCard.Fit2DDistributionCardResult | MoodTestNSampCard.MoodTestNSampCardResult | BivariateBoxPlotCard.BivariateBoxPlotCardResult | UnivariateSummaryStatsCard.UnivariateSummaryStatsCardResult | CorrelationMatrixCard.CorrelationMatrixCardResult | Chi2IndTestCard.Chi2IndTestCardResult | TZTest1SampCard.TZTest1SampCardResult | ShapiroNormalityTestCard.ShapiroNormalityTestCardResult | GroupedCompiledCardWrapper.GroupedCardResult | ColumnCard.ColumnCardResult | FitDistributionCard.FitDistributionCardResult | UnivariateHeaderCard.UnivariateHeaderCardResult | MoodTest2SampCard.MoodTest2SampCardResult | TTest2SampCard.TTest2SampCardResult | QuantilesTableCard.QuantilesTableCardResult | NumericalHistogramCard.NumericalHistogramCardResult | UnivariateFrequencyTableCard.UnivariateFrequencyTableCardResult | WorksheetRootCard.WorksheetRootCardResult;

export namespace CardResult {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailableCardResult
     */
    export interface UnavailableCardResult extends _CardResult {
        code?: EdaErrorCodes | null;
        message?: string | null;
        reason: CardResult.UnavailabilityReason;

        // PolyJson type
        type: 'unavailable';
    }

    export function isUnavailableCardResult(toTest: CardResult): toTest is UnavailableCardResult { return 'unavailable' === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailabilityReason
     */
    export enum UnavailabilityReason {
        NOT_COMPUTED = 'NOT_COMPUTED',
        FAILURE = 'FAILURE'
    }
}