import { Component, SimpleChanges, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NumericalHistogramCard, IntervalFilter, BinningMode } from 'src/generated-sources';
import { EChartOption } from 'echarts';
import { encodeHTML } from 'entities';
import _ from 'lodash';
import d3 from 'd3';
import { CardAction, CardActionType } from '@features/eda/worksheet/cards/events';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { ColorsService } from '@shared/graphics/colors.service';
import { getNumericalChartAxisTicks } from '@features/eda/echarts-utils';
import { PatternsService } from '@shared/graphics/patterns.service';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';

const BAR_COLOR = '#c4dffe';

@Component({
    selector: 'numerical-histogram-card-body',
    templateUrl: './numerical-histogram-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './numerical-histogram-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericalHistogramCardBodyComponent implements OnChanges {
    @Input() results: NumericalHistogramCard.NumericalHistogramCardResult;
    @Input() params: NumericalHistogramCard;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    histogramOptions: EChartOption | undefined;

    constructor(
        private colorsService: ColorsService,
        private patternsService: PatternsService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results || changes.params) {
            if (this.params.showHistogram && this.params.binningMode === BinningMode.CUSTOM) {
                this.histogramOptions = this.buildBarChartOptions();
            } else {
                this.histogramOptions = this.buildChartOptions(this.results);
            }
        }
    }

    buildBarChartOptions(): EChartOption | undefined {
        const labels = this.results.histogram!.bins.map(b => filterName(b));
        const data = this.results.histogram!.counts!;
        const series = [];
        const highlightData = this.results.histogram!.highlightedCounts;

        const tooltipFormatter = (value: any) => {
            const dataIndex = value.dataIndex;
            let tooltip = encodeHTML(this.params.column.name)
                + ': <b>'
                + encodeHTML(labels[dataIndex])
                + '</b>';
            tooltip += '<br>Count: <b>' + encodeHTML('' + data[dataIndex]) + '</b>';
            if (highlightData) {
                tooltip += '<br>Selected count: <b>' + encodeHTML('' + highlightData![dataIndex]) + '</b>';
            }

            return tooltip;
        };

        if (highlightData) {
            series.push({
                type: 'bar',
                stack: 'stack',
                data: highlightData.map((val, i) => {
                    const itemColor = this.colorsService.getColorForVariable(labels[i]);
                    const borderColor = d3.rgb(itemColor).darker(2).toString();
                    const stripedPattern = this.patternsService.getStripePattern(borderColor, itemColor);
                    return {
                        value: val,
                        itemStyle: {
                            color: stripedPattern,
                            barBorderColor: borderColor,
                            barBorderWidth: 1
                        }
                    };
                }),
                tooltip: { formatter: tooltipFormatter },
            });
        }

        series.push({
            type: 'bar',
            stack: 'stack',
            data: data.map((val, i) => {
                const color = this.colorsService.getColorForVariable(labels[i]);
                if (highlightData) {
                    val = val - highlightData[i];
                }
                return {
                    value: val,
                    itemStyle: { color },
                    emphasis: { itemStyle: { color } }
                };
            }),
            tooltip: { formatter: tooltipFormatter }
        });

        return {
            grid: { left: 0, top: 10, right: 0, bottom: 0, containLabel: true },
            tooltip: {
                confine: true,
                trigger: 'item',
                axisPointer: { type: 'none' }
            },
            xAxis: {
                type: 'category',
                data: labels,
                axisLabel: { color: '#999999' },
                axisTick: { show: true },
                axisLine: { show: true }
            },
            yAxis: {
                type: 'value',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { color: '#999999' }
            },
            series,
            animation: false
        };
    }

    chartClicked(event: any) {
        if (event.componentType === 'series'
            && (event.componentSubType === 'custom' || event.componentSubType === 'bar')
            && this.results.histogram) {
            const index: number = event.dataIndex;
            let filter = this.results.histogram.bins[index];
            if (event.event.event.shiftKey
                && filter.type === 'interval'
                && this.params.highlightFilter
                && this.params.highlightFilter.type === 'interval'
                && this.params.highlightFilter.column === this.params.column.name) {
                filter = {
                    column: this.params.column.name,
                    type: 'interval',
                    closed: IntervalFilter.ClosedMode.LEFT,
                    left: Math.min(filter.left, this.params.highlightFilter.left),
                    right: Math.max(filter.right, this.params.highlightFilter.right)
                };
            }

            this.action.emit({
                type: CardActionType.HIGHLIGHT,
                filter: { ...filter, name: this.params.column.name + ': ' + filterName(filter) }
            });
        }
    }

    buildChartOptions(results: NumericalHistogramCard.NumericalHistogramCardResult): EChartOption | undefined {
        const series: EChartOption.Series[] = [];
        let maxCount = 1;
        let minVal = Number.MAX_VALUE;
        let maxVal = -Number.MAX_VALUE;
        let boxPlotInverseRatio = 1;

        if (!results.histogram && !results.boxPlot) {
            // Nothing to show
            return;
        }

        if (results.histogram) {
            // Boxplot take 1/6 of vertical space
            boxPlotInverseRatio = 6;
        }

        if (results.histogram) {
            // Format [min, max, count, label, highlightedCount?][]
            type HistogramBar = [number, number, number, string, number | undefined | null];
            const histogramData: HistogramBar[] = [];
            for (let i = 0; i < results.histogram.bins.length; i++) {
                const bin = results.histogram.bins[i] as IntervalFilter;
                const count = results.histogram.counts[i];
                const highlightedCount = results.histogram.highlightedCounts && results.histogram.highlightedCounts[i];
                histogramData.push([bin.left, bin.right, count, filterName(bin), highlightedCount]);
            }

            maxCount = _.chain(histogramData).map(2).max().value() * (1 + 1 / boxPlotInverseRatio) + 1;
            minVal = _.chain(histogramData).map(0).min().value();
            maxVal = _.chain(histogramData).map(1).max().value();

            const tooltipFormatter = ({ value }: { value: HistogramBar }) => {
                let tooltip = encodeHTML(this.params.column.name)
                    + ': <b>'
                    + encodeHTML(value[3])
                    + '</b>';
                tooltip += '<br>Count: <b>' + encodeHTML('' + value[2]) + '</b>';
                if (this.results.histogram!.highlightedCounts) {
                    tooltip += '<br>Selected count: <b>' + encodeHTML('' + value[4]) + '</b>';
                }

                return tooltip;
            };

            const normalStyle = { fill: BAR_COLOR, borderWidth: 0 };
            const darkenedBarColor = d3.rgb(BAR_COLOR).darker(2).toString();
            const highlightedStyle = {
                borderWidth: 0,
                fill: this.patternsService.getStripePattern(darkenedBarColor, BAR_COLOR)
            };

            series.push({
                type: 'custom',
                tooltip: { formatter: tooltipFormatter },
                yAxisIndex: 0,
                renderItem: (params: any, api: any) => {
                    const yValue = api.value(2);
                    const highlightedHValue = api.value(4);
                    const start = api.coord([api.value(0), yValue]);
                    const size = api.size([api.value(1) - api.value(0), results.histogram!.highlightedCounts ? yValue - highlightedHValue : yValue]);

                    return {
                        type: 'rect',
                        shape: {
                            x: start[0],
                            y: start[1],
                            width: size[0],
                            height: size[1]
                        },
                        style: api.style(normalStyle),
                        styleEmphasis: api.styleEmphasis(normalStyle)
                    };
                },
                data: histogramData
            } as EChartOption.Series);

            if (results.histogram!.highlightedCounts) {
                // "Augmented" area of the highlighted zone (to create borders)
                series.push({
                    type: 'custom',
                    yAxisIndex: 0,
                    silent: true,
                    renderItem: (params: any, api: any) => {
                        const highlightedHValue = api.value(4);
                        const highlightedStart = api.coord([api.value(0), highlightedHValue]);
                        const highlightedSize = api.size([api.value(1) - api.value(0), highlightedHValue]);

                        return {
                            type: 'rect',
                            shape: {
                                x: highlightedStart[0] - 1,
                                y: highlightedStart[1],
                                width: highlightedSize[0] + 2,
                                height: highlightedSize[1]
                            },
                            style: api.style({ fill: darkenedBarColor, borderWidth: 0 })
                        };
                    },
                    data: histogramData
                } as EChartOption.Series);

                // Highlighted (striped) area
                series.push({
                    tooltip: { formatter: tooltipFormatter },
                    type: 'custom',
                    yAxisIndex: 0,
                    renderItem: (params: any, api: any) => {
                        const highlightedHValue = api.value(4);
                        const highlightedStart = api.coord([api.value(0), highlightedHValue]);
                        const highlightedSize = api.size([api.value(1) - api.value(0), highlightedHValue]);

                        return {
                            type: 'rect',
                            shape: {
                                x: highlightedStart[0],
                                y: highlightedStart[1] + 1,
                                width: highlightedSize[0],
                                height: highlightedSize[1] - 1
                            },
                            style: api.style(highlightedStyle),
                            styleEmphasis: api.styleEmphasis(highlightedStyle)
                        };

                    },
                    data: histogramData
                } as EChartOption.Series);
            }
        }

        if (results.boxPlot) {
            minVal = Math.min(results.boxPlot.min!, minVal);
            maxVal = Math.max(results.boxPlot.max!, maxVal);

            series.push({
                tooltip: {
                    formatter: (param: { data: number[] }) => {
                        return encodeHTML(this.params.column.name)
                            + ':<br>' + [
                                '&bull; 1st percentile: ' + encodeHTML(smarterNumber(param.data[1])),
                                '&bull; 1st quartile: ' + encodeHTML(smarterNumber(param.data[2])),
                                '&bull; Median: ' + encodeHTML(smarterNumber(param.data[3])),
                                '&bull; 3rd quartile: ' + encodeHTML(smarterNumber(param.data[4])),
                                '&bull; 99th percentile: ' + encodeHTML(smarterNumber(param.data[5]))
                            ].join('<br/>');
                    }
                },
                type: 'boxplot',
                coordinateSystem: 'cartesian2d',
                yAxisIndex: 1,
                data: [
                    // Fake data to position/size the boxplot according to requested ratio
                    ..._.fill(new Array(boxPlotInverseRatio - 1), []),
                    [
                        results.boxPlot.pc01 || 0,
                        results.boxPlot.pc25 || 0,
                        results.boxPlot.median || 0,
                        results.boxPlot.pc75 || 0,
                        results.boxPlot.pc99 || 0
                    ]
                ]
            });
        }

        const splitXAxis = 5;
        const ticks: number[] = getNumericalChartAxisTicks(splitXAxis, minVal, maxVal);
        const maxTickLen: number = Math.max(...ticks.map(v => ('' + v).length));
        let rotate = 0;
        if (maxTickLen > 4) {
            rotate = 45;
        }

        return {
            tooltip: {
                confine: true
            },
            color: ['#3398DB'],
            animation: false,
            grid: { left: 0, top: 5, right: 0, bottom: 0, containLabel: true },
            xAxis: [{
                type: 'value',
                scale: false,
                min: minVal,
                max: maxVal,
                splitNumber: splitXAxis,
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: {
                    color: '#999999',
                    rotate,
                    formatter: (value: number) => {
                        let stringRepr = '' + value;
                        if (stringRepr.length > 6) {
                            stringRepr = value.toPrecision(5);
                        }
                        return stringRepr;
                    }
                }
            }],
            yAxis: [
                {
                    type: 'value',
                    min: 0,
                    max: maxCount,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {
                        color: '#999999',
                        formatter: (value: number) => {
                            if (value === maxCount) {
                                return '';
                            }

                            let stringRepr = '' + value;
                            if (stringRepr.length > 6) {
                                stringRepr = value.toPrecision(5);
                            }
                            return stringRepr;
                        }
                    },
                    show: !!results.histogram
                }, {
                    type: 'category',
                    show: false
                }
            ],
            series
        };
    }
}
