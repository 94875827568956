import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], query: string, key?: string) {
        let results = items ? items : [];
        query = query.toLowerCase();

        if (query) {
            results = items.filter(item => {
                const i = key ? item[key].toLowerCase() : item.toLowerCase();
                return i.indexOf(query) >= 0;
            });
        }

        return results;
    }
}