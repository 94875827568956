import {Card, _Card} from './card';
import {CategoricalHistogramCard} from './univariate_header/categorical-histogram-card';
import {FitDistributionCard} from './fit-distribution-card';
import {NumericalHistogramCard} from './univariate_header/numerical-histogram-card';
import {QuantilesTableCard} from './univariate_header/quantiles-table-card';
import {ShapiroNormalityTestCard} from './shapiro-normality-test-card';
import {SignTest1SampCard} from './sign-test1-samp-card';
import {TZTest1SampCard} from './tztest1-samp-card';
import {UnivariateFrequencyTableCard} from './univariate_header/univariate-frequency-table-card';
import {UnivariateSummaryStatsCard} from './univariate_header/univariate-summary-stats-card';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.UnivariateCard
 */
export interface _UnivariateCard extends _Card {
    column: Variable;
}

export type UnivariateCard = SignTest1SampCard | CategoricalHistogramCard | ShapiroNormalityTestCard | UnivariateFrequencyTableCard | QuantilesTableCard | UnivariateSummaryStatsCard | NumericalHistogramCard | FitDistributionCard | TZTest1SampCard;

export function isUnivariateCard(toTest: Card): toTest is UnivariateCard { return ["sign_test_1samp" , "categorical_histogram" , "shapiro" , "univariate_frequency_table" , "quantile_table" , "univariate_summary" , "numerical_histogram" , "fit_distribution" , "ttest_ztest_1samp"].includes(toTest.type); }