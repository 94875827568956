import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, forwardRef, ElementRef, ViewChild, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'basic-list-form',
  templateUrl: './basic-list-form.component.html',
  styleUrls: ['./basic-list-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BasicListFormComponent),
        multi: true
      }
  ]
})
export class BasicListFormComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() showButton = true;
    @Input() autocompleteSuggestions?: string[]
    items: string[] = [];
    editMode = false;
    listItem = '';
    filteredSuggestions: string[] = [];
    @ViewChild('addInput') addInput: ElementRef;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    addItem = new Subject<string>();

    propagateChange = (_: any) => {};

    ngOnInit() {
        if (!this.showButton) {
            this.editMode = true;
        }
    }

    addListItem(event: KeyboardEvent | MouseEvent) {
        if (this.items.indexOf(this.listItem) >= 0 || (event as KeyboardEvent).repeat) {
            return;
        } else {
            this.items = [...this.items, this.listItem];
            this.propagateChange(this.items);
        }

        this.listItem = '';
        this.updateSuggestions();
    }

    updateSuggestions() {
        this.filteredSuggestions = [];
        if(this.autocompleteSuggestions) {
            const lowercaseValue = this.listItem.toLowerCase();
            for(const candidate of this.autocompleteSuggestions) {
                if(candidate && candidate.toLowerCase().includes(lowercaseValue)
                    && !this.items.includes(candidate)) {
                    this.filteredSuggestions.push(candidate);
                }
            }
        }
        this.filteredSuggestions.sort();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.autocompleteSuggestions) {
            this.updateSuggestions();
        }
    }

    removeListItem(value: any) {
        this.items = this.items.filter((item: any) => {
            return item !== value;
        });

        this.propagateChange(this.items);
        this.updateSuggestions();
    }

    activateGroup() {
        this.editMode = true;
        this.changeDetectorRef.detectChanges();

        this.addInput.nativeElement.focus();
    }

    writeValue(items: string[]) {
        if (items) {
            this.items = items;
        }
        this.updateSuggestions();
    }

    registerOnChange(fn: (_: any) => {}) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}
}
