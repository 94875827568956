
<div class="modal modal--analysis" [qa]="'eda-wizard-modal'">
    <modal-header [title]="title" [icon]="state === WizardState.ANALYSIS ? '' : 'icon-arrow-left'" (iconAction)="goToPreviousState(state)" [hasBorder]="false"></modal-header>
    <div class="modal-body flex modal-body--analysis no-padding" [ngClass]="{'modal-body--test': state === WizardState.TEST }">
        <ng-container [ngSwitch]="state">
            <ng-container *ngSwitchCase="WizardState.VARIABLES">
                <card-editor
                    [params]="params"
                    (paramsChange)="changeParams($event)"
                    (validityChange)="changeValidity($event)"
                ></card-editor>
            </ng-container>
            <ng-container *ngSwitchCase="WizardState.ANALYSIS">
                <div class="new-card card-wizard__body--wide">
                    <a class="new-card-type" (click)="setCardType('univariate_header')"
                        [qa]="{'eda-wizard-navigate': {'category': 'univariate'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Univariate analysis</h5>
                            <p class="new-card-type__description">
                                Discover your variables and see their distributions side-by-side.
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType('bivariate_header')"
                        [qa]="{'eda-wizard-navigate': {'category': 'bivariate'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Bivariate analysis</h5>
                            <p class="new-card-type__description">
                                Look into the relationship between pairs of variables simultaneously.
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="goToState(WizardState.TEST)"
                        [qa]="{'eda-wizard-navigate': {'category': 'test'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Statistical tests</h5>
                            <p class="new-card-type__description">
                                Make quantitative decisions by testing hypotheses.
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="goToState(WizardState.FIT)"
                        [qa]="{'eda-wizard-navigate': {'category': 'fit'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Fit curves & distributions</h5>
                            <p class="new-card-type__description">
                                Model variable distributions or relationships.
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType('correlation_matrix')"
                        [qa]="{'eda-wizard-navigate': {'category': 'correlation'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Correlation matrix</h5>
                            <p class="new-card-type__description">
                                Examine the relationship of multiple variables at the same time.
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType('pca')"
                        [qa]="{'eda-wizard-navigate': {'category': 'pca'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Principal Component Analysis</h5>
                            <p class="new-card-type__description">
                                Reduce a large set of variables into a smaller set of components.
                            </p>
                        </div>
                    </a>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="WizardState.FIT">
                <div class="card-wizard__body--wide">
                    <a class="new-card-type" (click)="setCardType('fit_distribution')"
                        [qa]="{'eda-wizard-navigate': {'category': 'fit-distribution'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Fit Distribution</h5>
                            <p class="new-card-type__description">
                                Estimate the parameters of the most common distributions (normal, exponential, ...)
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType('fit_2d_distribution')"
                        [qa]="{'eda-wizard-navigate': {'category': 'fit-2d-distribution'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">2D Fit Distribution</h5>
                            <p class="new-card-type__description">
                                Visualize the density of bivariate distributions (KDE and bivariate gaussian).
                            </p>
                        </div>
                    </a>
                    <a class="new-card-type" (click)="setCardType('fit_curve')"
                        [qa]="{'eda-wizard-navigate': {'category': 'fit-curve'}}">
                        <div class="new-card-type__body">
                            <h5 class="new-card-type__title">Fit Curve</h5>
                            <p class="new-card-type__description">
                                Model the relationship between two variables.
                            </p>
                        </div>
                    </a>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="WizardState.TEST">
                <div class="new-card new-card--test horizontal-flex card-wizard__body--wide">
                    <div class="new-card-menu">
                        <ul class="new-card__list">
                            <li (click)="setTestType(TestState.ONE_SAMPLE)" class="new-card-menu__item"
                                [ngClass]="{ 'new-card-menu__item--selected': selectedTest === TestState.ONE_SAMPLE }"
                                [qa]="{'eda-wizard-navigate': {'category': 'one-sample-test'}}">
                                <h5 class="new-card-menu__header">One-sample test</h5>
                                <span class="new-card-menu__subtext">1 <i class="icon-number"></i> variable</span>
                            </li>
                            <li (click)="setTestType(TestState.TWO_SAMPLE)" class="new-card-menu__item"
                                [ngClass]="{ 'new-card-menu__item--selected': selectedTest === TestState.TWO_SAMPLE }"
                                [qa]="{'eda-wizard-navigate': {'category': 'two-sample-test'}}">
                                <h5 class="new-card-menu__header">Two-sample test</h5>
                                <span class="new-card-menu__subtext">1 <i class="icon-number"></i> variable</span>
                                <span class="new-card-menu__subtext">1 <i class="icon-font"></i> variable with 2 modalities</span>
                            </li>
                            <li (click)="setTestType(TestState.N_SAMPLE)" class="new-card-menu__item"
                                [ngClass]="{ 'new-card-menu__item--selected': selectedTest === TestState.N_SAMPLE }"
                                [qa]="{'eda-wizard-navigate': {'category': 'n-sample-test'}}">
                                <h5 class="new-card-menu__header">N-sample test</h5>
                                <span class="new-card-menu__subtext">1 <i class="icon-number"></i> variable</span>
                                <span class="new-card-menu__subtext">1 <i class="icon-font"></i> variable with N modalities</span>
                            </li>
                            <li (click)="setTestType(TestState.PAIRED)" class="new-card-menu__item"
                                [ngClass]="{ 'new-card-menu__item--selected': selectedTest === TestState.PAIRED }"
                                [qa]="{'eda-wizard-navigate': {'category': 'categorical-test'}}">
                                <h5 class="new-card-menu__header">Categorical test</h5>
                                <span class="new-card-menu__subtext">2 <i class="icon-font"></i> variables</span>
                            </li>
                        </ul>
                    </div>
                    <div class="new-card-content">
                        <ng-container *ngIf="selectedTest === TestState.ONE_SAMPLE">
                            <h4 class="new-card-content__header">Location</h4>
                            <p>Compare the location parameters of a population to a hypothesized constant.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('ttest_ztest_1samp')"
                                        [qa]="{'eda-wizard-navigate': {'category': '1samp-ttest'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Student 𝘵-test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the mean of a population is a specified value.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('sign_test_1samp')"
                                        [qa]="{'eda-wizard-navigate': {'category': 'sign-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Sign Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the median of a population is a specified value.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <h4  class="new-card-content__header">Distribution</h4>
                                <p>Compare the distribution of a population to a hypothesized one.</p>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('shapiro')"
                                        [qa]="{'eda-wizard-navigate': {'category': 'shapiro'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Shapiro-Wilk Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether a population is normally distributed.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="selectedTest === TestState.TWO_SAMPLE">
                            <h4  class="new-card-content__header">Location</h4>
                            <p>Compare the location parameters of two populations.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('ttest_2samp')"
                                        [qa]="{'eda-wizard-navigate': {'category': '2samp-ttest'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Student 𝘵-test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the mean of two populations is equal.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('mood_test_2samp')"
                                        [qa]="{'eda-wizard-navigate': {'category': '2samp-mood-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Median Mood Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the median of two populations is equal.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <h4  class="new-card-content__header">Distribution</h4>
                                <p>Compare the distributions of two populations.</p>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('ks_test_2samp')"
                                        [qa]="{'eda-wizard-navigate': {'category': 'ks-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Kolmogorov-Smirnov</h5>
                                            <p class="new-card-type__description">
                                                Test whether the two populations are similarly distributed.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="selectedTest === TestState.N_SAMPLE">
                            <h4  class="new-card-content__header">Location</h4>
                            <p>Compare the location parameters of multiple populations.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('oneway_anova')"
                                        [qa]="{'eda-wizard-navigate': {'category': 'anova'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Oneway ANOVA</h5>
                                            <p class="new-card-type__description">
                                                Test whether the mean of all populations is equal.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('mood_nsamp')"
                                        [qa]="{'eda-wizard-navigate': {'category': 'nsamp-mood-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Median Mood Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether the median of all populations is equal.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('pairwise_ttest')"
                                        [qa]="{'eda-wizard-navigate': {'category': 'pairwise-ttest'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Pairwise Student 𝘵-test</h5>
                                            <p class="new-card-type__description">
                                                Test whether every pair of population has the same mean.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('pairwise_mood')"
                                        [qa]="{'eda-wizard-navigate': {'category': 'pairwise-mood-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Pairwise Median Mood Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether every pair of population has the same median.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul> 
                        </ng-container>
                        <ng-container *ngIf="selectedTest === TestState.PAIRED">
                            <h4  class="new-card-content__header">Independence</h4>
                            <p>Test independence between categorical variables.</p>
                            <ul class="new-card__list">
                                <li>
                                    <a class="new-card-type new-card-type--list"
                                        (click)="setCardType('chi2_independence_test')"
                                        [qa]="{'eda-wizard-navigate': {'category': 'chi2-test'}}">
                                        <div class="new-card-type__body">
                                            <h5 class="new-card-type__title">Chi-square Independence Test</h5>
                                            <p class="new-card-type__description">
                                                Test whether two categorical variables are independent.
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>                                       
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="state === WizardState.VARIABLES">
        <div class="modal-footer modal-footer-std-buttons">
            <div class="pull-right">
                <button
                    type="button"
                    class="btn btn--text btn--secondary"
                    (click)="dismiss()"
                    tabindex="-1"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    form="config-form"
                    class="btn btn--text btn--primary"
                    [disabled]="!canSave"
                    (click)="save()"
                    tabindex="-1"
                    [wt1Click]="'worksheets_create-card'"
                    [qa]="'eda-create-card-button'"
                >
                    Create Card
                </button>
            </div>
        </div>
    </ng-container>
</div>
    
