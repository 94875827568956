import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { FitCurveCard, Variable, Curve, isPolynomialCurve } from 'src/generated-sources';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { toggleFormControl } from '@utils/toggle-form-control';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { CardWizardVariable } from '@features/eda/card-models';
import { Observable } from 'rxjs';
import { isIntegerValidator } from '@utils/is-integer-validator';

@UntilDestroy()
@Component({
    selector: 'fit-curve-card-config',
    templateUrl: './fit-curve-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './fit-curve-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FitCurveCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: FitCurveCard;
    @Input() variables: Variable[];
    @Output() paramsChange = new EventEmitter<FitCurveCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    allVariables$: Observable<CardWizardVariable[]>;

    fitCurves = [{
        name: 'Polynomial',
        value: 'polynomial'
    }, {
        name: 'Isotonic',
        value: 'isotonic'
    }];

    curves = new FormArrayRepeat(() => {
        const type = this.fb.control('polynomial', [Validators.required]);

        const group = this.fb.group({
            type,
            degree: toggleFormControl(this.fb.control(2, [Validators.required, Validators.min(0), isIntegerValidator()]), type, 'polynomial')
        });

        return group;
    });

    configForm = this.fb.group({
        xColumn: this.fb.control(null, [Validators.required]),
        yColumn: this.fb.control(null, [Validators.required]),
        curves: this.curves
    });

    curveControls = (this.configForm.get('curves') as FormArray).controls;

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => this.paramsChange.emit({
                ...this.params,
                xColumn: formValue.xColumn,
                yColumn: formValue.yColumn,
                curves: formValue.curves
            }));

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    add() {
        this.curves.add();
    }

    remove(index: number) {
        this.curves.removeAt(index);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                xColumn: this.params.xColumn,
                yColumn: this.params.yColumn,
                curves: this.params.curves
            });
        }
    }

    ngOnDestroy() {

    }
}
