import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { ScatterPlotCard } from 'src/generated-sources';
import _ from 'lodash';
import { EChartOption } from 'echarts';
import { CardAction } from '../../events';
import { getNumericalChartAxisTicks } from '@features/eda/echarts-utils';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';

@Component({
    selector: 'scatter-plot-card-body',
    templateUrl: './scatter-plot-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './scatter-plot-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScatterPlotCardBodyComponent implements OnChanges {
    @Input() results: ScatterPlotCard.ScatterPlotCardResult;
    @Input() params: ScatterPlotCard;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    chartOptions: EChartOption;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results || changes.params) {
            this.chartOptions = this.buildChartOptions();
        }
    }

    buildChartOptions(): EChartOption {
        const allX = [...this.results.xSeries, ...(this.results.highlightedXSeries || [])];
        const allY = [...this.results.ySeries, ...(this.results.highlightedYSeries || [])];

        const minX = Math.floor(_.min(allX) || 0);
        const maxX = Math.ceil(_.max(allX) || 0);
        const minY = Math.floor(_.min(allY) || 0);
        const maxY = Math.ceil(_.max(allY) || 0);
        const hasHighlight = this.results.highlightedXSeries && this.results.highlightedYSeries;

        const series: EChartOption.SeriesScatter[] = [{
            type: 'scatter',
            z: 0,
            itemStyle: {
                color: hasHighlight ? '#AAAAAA' : '#1e7efa',
                opacity: 0.5
            },
            symbolSize: this.params.symbolSize,
            data: _.zip(this.results.xSeries, this.results.ySeries),
            large: true
        }];

        if (hasHighlight) {
            series.push({
                type: 'scatter',
                z: 0,
                itemStyle: { color: '#1e7efa', opacity: 0.5 },
                symbolSize: this.params.symbolSize,
                data: _.zip(this.results.highlightedXSeries, this.results.highlightedYSeries),
                large: true
            });
        }

        const splitXAxis = 5;
        const ticks: number[] = getNumericalChartAxisTicks(splitXAxis, minX, maxX);
        const maxTickLen: number = Math.max(...ticks.map(v => ('' + v).length));
        let rotate = 0;
        if (maxTickLen > 4) {
            rotate = 45;
        }

        return {
            animation: false,
            tooltip: {
                trigger: 'none',
                axisPointer: { type: 'cross' }
            },
            grid: { left: 0, top: 10, right: 0, bottom: 0, containLabel: true },
            xAxis: [{
                type: 'value',
                min: minX,
                max: maxX,
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: {
                    color: '#999999', rotate,
                    formatter: (value: number) => value === minX || value === maxX ? '' : value
                },
                axisPointer: {
                    label: {
                        formatter: ({ value }: { value: number }) => {
                            return this.params.xColumn.name + ': ' + smarterNumber(value);
                        }
                    }
                }
            }],
            yAxis: {
                type: 'value',
                min: minY,
                max: maxY,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    color: '#999999',
                    formatter: (value: number) => value === minY || value === maxY ? '' : value
                },
                axisPointer: {
                    label: {
                        formatter: ({ value }: { value: number }) => {
                            return this.params.yColumn.name + ': ' + smarterNumber(value);
                        }
                    }
                }
            },
            series
        };
    }
}
