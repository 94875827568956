import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {Filter} from './../../../compute/filtering/filter';
import {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard
 */
export interface ScatterPlotCard extends _BivariateCard {
    highlightFilter?: Filter | null;
    symbolSize: number;

    // PolyJson type
    type: 'scatter_plot';
}

export function isScatterPlotCard(toTest: Card): toTest is ScatterPlotCard { return 'scatter_plot' === toTest.type; }

export namespace ScatterPlotCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotCardResult
     */
    export interface ScatterPlotCardResult extends _CardResult {
        highlightedXSeries?: number[] | null;
        highlightedYSeries?: number[] | null;
        xSeries: number[];
        ySeries: number[];

        // PolyJson type
        type: 'scatter_plot';
    }

    export function isScatterPlotCardResult(toTest: CardResult): toTest is ScatterPlotCardResult { return 'scatter_plot' === toTest.type; }
}