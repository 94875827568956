export const GENERATED_MAPPING = {
    'com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard' : 'Chi2IndTestCard',
    'com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard$Chi2IndTestCardResult' : 'Chi2IndTestCard.Chi2IndTestCardResult',
    'com.dataiku.dip.eda.compute.distributions.JointNormal' : 'JointNormal',
    'com.dataiku.dip.eda.compute.distributions.JointNormal$FittedJointNormal' : 'JointNormal.FittedJointNormal',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard' : 'QuantilesTableCard',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard$QuantilesTableCardResult' : 'QuantilesTableCard.QuantilesTableCardResult',
    'com.dataiku.dip.coremodel.Checklists' : 'Checklists',
    'com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Fit2DDistributionResult' : 'Fit2DDistribution.Fit2DDistributionResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Density' : 'Fit2DDistribution.Density',
    'com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution' : 'Fit2DDistribution',
    'com.dataiku.dip.eda.compute.computations.univariate.Skewness$SkewnessResult' : 'Skewness.SkewnessResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Skewness' : 'Skewness',
    'com.dataiku.dip.eda.compute.distributions.Binomial$FittedBinomial' : 'Binomial.FittedBinomial',
    'com.dataiku.dip.eda.compute.distributions.Binomial' : 'Binomial',
    'com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova$OneWayAnovaResult' : 'OneWayAnova.OneWayAnovaResult',
    'com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova' : 'OneWayAnova',
    'com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStatType' : 'SummaryCardBuilder.ComputedStatType',
    'com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStat' : 'SummaryCardBuilder.ComputedStat',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard' : 'BivariateFrequencyTableCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard$BivariateFrequencyTableCardResult' : 'BivariateFrequencyTableCard.BivariateFrequencyTableCardResult',
    'com.dataiku.dip.dashboards.model.Dashboard$DashboardListItem' : 'Dashboard.DashboardListItem',
    'com.dataiku.dip.dashboards.model.Dashboard' : 'Dashboard',
    'com.dataiku.dip.input.DatasetOrdering$DatasetOrderingRule' : 'DatasetOrdering.DatasetOrderingRule',
    'com.dataiku.dip.input.DatasetOrdering' : 'DatasetOrdering',
    'com.dataiku.dip.eda.compute.computations.bivariate.Covariance$CovarianceResult' : 'Covariance.CovarianceResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Covariance' : 'Covariance',
    'com.dataiku.dip.coremodel.SerializedRecipe$SDep' : 'SerializedRecipe.SDep',
    'com.dataiku.dip.coremodel.SerializedRecipe$RecipeListItem' : 'SerializedRecipe.RecipeListItem',
    'com.dataiku.dip.coremodel.SerializedRecipe' : 'SerializedRecipe',
    'com.dataiku.dip.coremodel.SerializedRecipe$InputRole' : 'SerializedRecipe.InputRole',
    'com.dataiku.dip.coremodel.SerializedRecipe$RecipeInput' : 'SerializedRecipe.RecipeInput',
    'com.dataiku.dip.coremodel.SerializedRecipe$OutputRole' : 'SerializedRecipe.OutputRole',
    'com.dataiku.dip.coremodel.SerializedRecipe$RecipeOutput' : 'SerializedRecipe.RecipeOutput',
    'com.dataiku.dip.server.datasets.DatasetMetricsStatusService$DatasetSummaryStatus' : 'DatasetMetricsStatusService.DatasetSummaryStatus',
    'com.dataiku.dip.server.datasets.DatasetsCRUDController$DatasetListItem' : 'DatasetsCRUDController.DatasetListItem',
    'com.dataiku.dip.server.datasets.DatasetsCRUDController$RecipeLink' : 'DatasetsCRUDController.RecipeLink',
    'com.dataiku.dip.eda.compute.filtering.AnumFilter' : 'AnumFilter',
    'com.dataiku.dip.eda.compute.computations.univariate.Shapiro$ShapiroResult' : 'Shapiro.ShapiroResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Shapiro' : 'Shapiro',
    'com.dataiku.dip.eda.compute.computations.NotComputedResult' : 'NotComputedResult',
    'com.dataiku.dip.eda.compute.sampling.DataSpec' : 'DataSpec',
    'com.dataiku.dip.eda.compute.grouping.CrossGrouping$CrossGroupingResult' : 'CrossGrouping.CrossGroupingResult',
    'com.dataiku.dip.eda.compute.grouping.CrossGrouping' : 'CrossGrouping',
    'com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues$ListMostFrequentValuesResult' : 'ListMostFrequentValues.ListMostFrequentValuesResult',
    'com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues' : 'ListMostFrequentValues',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$TestDistributionResult' : 'TestDistribution.TestDistributionResult',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$ProbabilityMassFunction' : 'TestDistribution.ProbabilityMassFunction',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$ProbabilityDensityFunction' : 'TestDistribution.ProbabilityDensityFunction',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$LikelihoodTestsResult' : 'TestDistribution.LikelihoodTestsResult',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$PlotData' : 'TestDistribution.PlotData',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution' : 'TestDistribution',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$KolmogorovSmirnovTestResult' : 'TestDistribution.KolmogorovSmirnovTestResult',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$StatTests' : 'TestDistribution.StatTests',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$QQPlotData' : 'TestDistribution.QQPlotData',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard' : 'BivariateHistogramCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramData' : 'BivariateHistogramCard.BivariateHistogramData',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramCardResult' : 'BivariateHistogramCard.BivariateHistogramCardResult',
    'com.dataiku.dip.eda.compute.filtering.AndFilter' : 'AndFilter',
    'com.dataiku.dip.eda.compute.distributions.Poisson' : 'Poisson',
    'com.dataiku.dip.eda.compute.distributions.Poisson$FittedPoisson' : 'Poisson.FittedPoisson',
    'com.dataiku.dip.server.services.ProjectsService$UIProject' : 'ProjectsService.UIProject',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard' : 'NumericalHistogramCard',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard$NumericalHistogramCardResult' : 'NumericalHistogramCard.NumericalHistogramCardResult',
    'com.dataiku.dip.eda.compute.curves.PolynomialCurve$ParametrizedPolynomialCurve' : 'PolynomialCurve.ParametrizedPolynomialCurve',
    'com.dataiku.dip.eda.compute.curves.PolynomialCurve' : 'PolynomialCurve',
    'com.dataiku.dip.eda.worksheets.models.Variable' : 'Variable',
    'com.dataiku.dip.eda.worksheets.models.Variable$Type' : 'Variable.Type',
    'com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp' : 'SignTest1Samp',
    'com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp$SignTest1SampResult' : 'SignTest1Samp.SignTest1SampResult',
    'com.dataiku.dip.partitioning.Dimension' : 'Dimension',
    'com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture$Component' : 'NormalMixture.FittedNormalMixture.Component',
    'com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture' : 'NormalMixture.FittedNormalMixture',
    'com.dataiku.dip.eda.compute.distributions.NormalMixture' : 'NormalMixture',
    'com.dataiku.dip.eda.worksheets.cards.MultivariateCard' : 'MultivariateCard',
    'com.dataiku.dip.datasets.DatasetSelectionToMemTable' : 'DatasetSelectionToMemTable',
    'com.dataiku.dip.eda.compute.filtering.IntervalFilter$ClosedMode' : 'IntervalFilter.ClosedMode',
    'com.dataiku.dip.eda.compute.filtering.IntervalFilter' : 'IntervalFilter',
    'com.dataiku.dip.eda.compute.filtering.MissingValueFilter' : 'MissingValueFilter',
    'com.dataiku.dip.eda.compute.computations.common.CountGroups$CountGroupsResult' : 'CountGroups.CountGroupsResult',
    'com.dataiku.dip.eda.compute.computations.common.CountGroups' : 'CountGroups',
    'com.dataiku.dip.eda.compute.computations.ComputationResult$ComputationCounts' : 'ComputationResult.ComputationCounts',
    'com.dataiku.dip.eda.compute.computations.ComputationResult' : 'ComputationResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest$Chi2IndTestResult' : 'Chi2IndTest.Chi2IndTestResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest' : 'Chi2IndTest',
    'com.dataiku.dip.eda.worksheets.cards.fragments.NumericalHistogramFragment' : 'NumericalHistogramFragment',
    'com.dataiku.dip.dashboards.model.DashboardPage' : 'DashboardPage',
    'com.dataiku.dip.eda.compute.computations.univariate.Sum$SumResult' : 'Sum.SumResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Sum' : 'Sum',
    'com.dataiku.dip.eda.compute.computations.univariate.Variance' : 'Variance',
    'com.dataiku.dip.eda.compute.computations.univariate.Variance$VarianceResult' : 'Variance.VarianceResult',
    'com.dataiku.dip.partitioning.ExactValueDimension' : 'ExactValueDimension',
    'com.dataiku.dip.eda.worksheets.WorksheetsService$WorksheetHead' : 'WorksheetsService.WorksheetHead',
    'com.dataiku.dip.eda.worksheets.cards.common.HeatmapParams' : 'HeatmapParams',
    'com.dataiku.dip.eda.worksheets.cards.UnivariateCard' : 'UnivariateCard',
    'com.dataiku.dip.eda.compute.computations.univariate.Kurtosis$KurtosisResult' : 'Kurtosis.KurtosisResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Kurtosis' : 'Kurtosis',
    'com.dataiku.dip.eda.compute.distributions.KDE2D' : 'KDE2D',
    'com.dataiku.dip.eda.compute.distributions.KDE2D$FittedKDE2D' : 'KDE2D.FittedKDE2D',
    'com.dataiku.dip.eda.compute.computations.univariate.Min$MinResult' : 'Min.MinResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Min' : 'Min',
    'com.dataiku.dip.eda.compute.distributions.Distribution2D$FittedDistribution2D' : 'Distribution2D.FittedDistribution2D',
    'com.dataiku.dip.eda.compute.distributions.Distribution2D' : 'Distribution2D',
    'com.dataiku.dip.eda.compute.computations.UnavailableResult' : 'UnavailableResult',
    'com.dataiku.dip.server.services.ReadOnlyJobsInternalDB$ObjectBuild' : 'ReadOnlyJobsInternalDB.ObjectBuild',
    'com.dataiku.dip.eda.compute.stats.PValueAdjustmentMethod' : 'PValueAdjustmentMethod',
    'com.dataiku.dip.partitioning.TimeDimension' : 'TimeDimension',
    'com.dataiku.dip.partitioning.TimeDimension$Period' : 'TimeDimension.Period',
    'com.dataiku.dip.server.services.ITaggingService$TaggableType' : 'ITaggingService.TaggableType',
    'com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiCondition' : 'FilterDesc.FilterUiCondition',
    'com.dataiku.dip.dataflow.exec.filter.FilterDesc$ExpressionLanguage' : 'FilterDesc.ExpressionLanguage',
    'com.dataiku.dip.dataflow.exec.filter.FilterDesc' : 'FilterDesc',
    'com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiData' : 'FilterDesc.FilterUiData',
    'com.dataiku.dip.eda.worksheets.cards.common.GroupedCompiledCardWrapper$GroupedCardResult' : 'GroupedCompiledCardWrapper.GroupedCardResult',
    'com.dataiku.dip.eda.compute.grouping.AnumGrouping$AnumGroupingResult' : 'AnumGrouping.AnumGroupingResult',
    'com.dataiku.dip.eda.compute.grouping.AnumGrouping' : 'AnumGrouping',
    'com.dataiku.dip.eda.compute.distributions.Triangular$FittedTriangular' : 'Triangular.FittedTriangular',
    'com.dataiku.dip.eda.compute.distributions.Triangular' : 'Triangular',
    'com.dataiku.dip.eda.worksheets.cards.fragments.CountCompleteFragment' : 'CountCompleteFragment',
    'com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation' : 'MutualInformation',
    'com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation$MutualInformationResult' : 'MutualInformation.MutualInformationResult',
    'com.dataiku.dip.coremodel.ExposedObject' : 'ExposedObject',
    'com.dataiku.dip.coremodel.ExposedObject$Rule' : 'ExposedObject.Rule',
    'com.dataiku.dip.eda.compute.computations.common.GroupedComputation' : 'GroupedComputation',
    'com.dataiku.dip.eda.compute.computations.common.GroupedComputation$GroupedComputationResult' : 'GroupedComputation.GroupedComputationResult',
    'com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp$KsTest2SampResult' : 'KsTest2Samp.KsTest2SampResult',
    'com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp' : 'KsTest2Samp',
    'com.dataiku.dip.eda.compute.computations.multivariate.PCA' : 'PCA',
    'com.dataiku.dip.eda.compute.computations.multivariate.PCA$PCAResult' : 'PCA.PCAResult',
    'com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard' : 'MoodTestNSampCard',
    'com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard$MoodTestNSampCardResult' : 'MoodTestNSampCard.MoodTestNSampCardResult',
    'com.dataiku.dip.eda.worksheets.cards.PCACard' : 'PCACard',
    'com.dataiku.dip.eda.worksheets.cards.PCACard$PCACardResult' : 'PCACard.PCACardResult',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$HistogramData' : 'CategoricalHistogramCard.HistogramData',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$CategoricalHistogramCardResult' : 'CategoricalHistogramCard.CategoricalHistogramCardResult',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard' : 'CategoricalHistogramCard',
    'com.dataiku.dip.eda.compute.distributions.LogNormal$FittedLogNormal' : 'LogNormal.FittedLogNormal',
    'com.dataiku.dip.eda.compute.distributions.LogNormal' : 'LogNormal',
    'com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest' : 'PairwiseMoodTest',
    'com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest$PairwiseMoodTestResult' : 'PairwiseMoodTest.PairwiseMoodTestResult',
    'com.dataiku.dip.eda.compute.distributions.Distribution' : 'Distribution',
    'com.dataiku.dip.eda.compute.distributions.Distribution$FittedDistribution' : 'Distribution.FittedDistribution',
    'com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard' : 'Fit2DDistributionCard',
    'com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard$Fit2DDistributionCardResult' : 'Fit2DDistributionCard.Fit2DDistributionCardResult',
    'com.dataiku.dip.server.services.ExploresService$DatasetChart' : 'ExploresService.DatasetChart',
    'com.dataiku.dip.eda.compute.distributions.Exponential$FittedExponential' : 'Exponential.FittedExponential',
    'com.dataiku.dip.eda.compute.distributions.Exponential' : 'Exponential',
    'com.dataiku.dip.eda.compute.distributions.Beta$FittedBeta' : 'Beta.FittedBeta',
    'com.dataiku.dip.eda.compute.distributions.Beta' : 'Beta',
    'com.dataiku.dip.coremodel.SerializedProject$ReaderAuthorization$Mode' : 'SerializedProject.ReaderAuthorization.Mode',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectExposedObjects' : 'SerializedProject.ProjectExposedObjects',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectRelocationSettings' : 'SerializedProject.ProjectRelocationSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectWikiSettings' : 'SerializedProject.ProjectWikiSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectSettings' : 'SerializedProject.ProjectSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectSparkEngineCreationSettings' : 'SerializedProject.ProjectSparkEngineCreationSettings',
    'com.dataiku.dip.coremodel.SerializedProject$LimitsSettings' : 'SerializedProject.LimitsSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectType' : 'SerializedProject.ProjectType',
    'com.dataiku.dip.coremodel.SerializedProject' : 'SerializedProject',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectHiveEngineCreationSettings' : 'SerializedProject.ProjectHiveEngineCreationSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectIntegration' : 'SerializedProject.ProjectIntegration',
    'com.dataiku.dip.coremodel.SerializedProject$PermissionItem' : 'SerializedProject.PermissionItem',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectDashboardAuthorizations' : 'SerializedProject.ProjectDashboardAuthorizations',
    'com.dataiku.dip.coremodel.SerializedProject$IntegrationsSettings' : 'SerializedProject.IntegrationsSettings',
    'com.dataiku.dip.coremodel.SerializedProject$SQLLikeRecipeInitializationMode' : 'SerializedProject.SQLLikeRecipeInitializationMode',
    'com.dataiku.dip.coremodel.SerializedProject$ReaderAuthorization' : 'SerializedProject.ReaderAuthorization',
    'com.dataiku.dip.coremodel.SerializedProject$FlowBuildSettings' : 'SerializedProject.FlowBuildSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectImpalaEngineCreationSettings' : 'SerializedProject.ProjectImpalaEngineCreationSettings',
    'com.dataiku.dip.coremodel.SerializedProject$AdditionalDashboardUsers' : 'SerializedProject.AdditionalDashboardUsers',
    'com.dataiku.dip.coremodel.SerializedProject$AdditionalDashboardUser' : 'SerializedProject.AdditionalDashboardUser',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard' : 'BivariateSummaryStatsCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$BivariateSummaryStatsCardResult' : 'BivariateSummaryStatsCard.BivariateSummaryStatsCardResult',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$StatsToCompute' : 'BivariateSummaryStatsCard.StatsToCompute',
    'com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard$SignTest1SampCardResult' : 'SignTest1SampCard.SignTest1SampCardResult',
    'com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard' : 'SignTest1SampCard',
    'com.dataiku.dip.server.UsabilityComputer$ListSettings' : 'UsabilityComputer.ListSettings',
    'com.dataiku.dip.server.UsabilityComputer$UsableComputable' : 'UsabilityComputer.UsableComputable',
    'com.dataiku.dip.server.UsabilityComputer$Usable' : 'UsabilityComputer.Usable',
    'com.dataiku.dip.eda.compute.curves.IsotonicCurve$ParametrizedIsotonicCurve' : 'IsotonicCurve.ParametrizedIsotonicCurve',
    'com.dataiku.dip.eda.compute.curves.IsotonicCurve' : 'IsotonicCurve',
    'com.dataiku.dip.eda.compute.computations.univariate.PairwiseTTest$PairwiseTTestResult' : 'PairwiseTTest.PairwiseTTestResult',
    'com.dataiku.dip.eda.compute.computations.univariate.PairwiseTTest' : 'PairwiseTTest',
    'com.dataiku.dip.eda.compute.sampling.Sample' : 'Sample',
    'com.dataiku.dip.eda.worksheets.cards.fragments.BivariateFrequencyTableFragment' : 'BivariateFrequencyTableFragment',
    'com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMetric' : 'CorrelationMatrixCard.CorrelationMetric',
    'com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard' : 'CorrelationMatrixCard',
    'com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMatrixCardResult' : 'CorrelationMatrixCard.CorrelationMatrixCardResult',
    'com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailableCardResult' : 'CardResult.UnavailableCardResult',
    'com.dataiku.dip.eda.worksheets.cards.CardResult' : 'CardResult',
    'com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailabilityReason' : 'CardResult.UnavailabilityReason',
    'com.dataiku.dip.coremodel.Schema' : 'Schema',
    'com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard$OneWayANOVACardResult' : 'OneWayANOVACard.OneWayANOVACardResult',
    'com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard' : 'OneWayANOVACard',
    'com.dataiku.dip.coremodel.SerializedDataset' : 'SerializedDataset',
    'com.dataiku.dip.coremodel.SerializedDataset$RemoteFilesSource' : 'SerializedDataset.RemoteFilesSource',
    'com.dataiku.dip.coremodel.SerializedDataset$SerializedCheck' : 'SerializedDataset.SerializedCheck',
    'com.dataiku.dip.coremodel.SerializedDataset$CrossProjectBuildBehaviour' : 'SerializedDataset.CrossProjectBuildBehaviour',
    'com.dataiku.dip.coremodel.SerializedDataset$RebuildBehavior' : 'SerializedDataset.RebuildBehavior',
    'com.dataiku.dip.coremodel.SerializedDataset$ReadWriteOptions' : 'SerializedDataset.ReadWriteOptions',
    'com.dataiku.dip.coremodel.SerializedDataset$RemoteFiles' : 'SerializedDataset.RemoteFiles',
    'com.dataiku.dip.coremodel.SerializedDataset$FlowOptions' : 'SerializedDataset.FlowOptions',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard$AbstractHeaderCardResult' : 'AbstractHeaderCard.AbstractHeaderCardResult',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard' : 'AbstractHeaderCard',
    'com.dataiku.dip.coremodel.VersionTag' : 'VersionTag',
    'com.dataiku.dip.coremodel.VersionTag$VersionTagUser' : 'VersionTag.VersionTagUser',
    'com.dataiku.dip.server.controllers.JupyterController$JupyterNotebookListItem' : 'JupyterController.JupyterNotebookListItem',
    'com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$FitCurveResult' : 'FitCurve.FitCurveResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$ScoreData' : 'FitCurve.ScoreData',
    'com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$PlotData' : 'FitCurve.PlotData',
    'com.dataiku.dip.eda.compute.computations.bivariate.FitCurve' : 'FitCurve',
    'com.dataiku.dip.coremodel.HeadWithVersioningInfo' : 'HeadWithVersioningInfo',
    'com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF' : 'EmpiricalCDF',
    'com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF$EmpiricalCDFResult' : 'EmpiricalCDF.EmpiricalCDFResult',
    'com.dataiku.dip.eda.compute.filtering.NotFilter' : 'NotFilter',
    'com.dataiku.dip.eda.compute.computations.AvailableResult' : 'AvailableResult',
    'com.dataiku.dip.eda.worksheets.models.BinningMode' : 'BinningMode',
    'com.dataiku.dip.partitioning.PartitioningScheme' : 'PartitioningScheme',
    'com.dataiku.dip.eda.compute.computations.common.Count' : 'Count',
    'com.dataiku.dip.eda.compute.computations.common.Count$CountResult' : 'Count.CountResult',
    'com.dataiku.dip.datasets.Type' : 'Type',
    'com.dataiku.dip.eda.compute.grouping.UnionGrouping' : 'UnionGrouping',
    'com.dataiku.dip.eda.compute.grouping.UnionGrouping$UnionGroupingResult' : 'UnionGrouping.UnionGroupingResult',
    'com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard' : 'ShapiroNormalityTestCard',
    'com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard$ShapiroNormalityTestCardResult' : 'ShapiroNormalityTestCard.ShapiroNormalityTestCardResult',
    'com.dataiku.dip.eda.compute.filtering.Filter' : 'Filter',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard$UnivariateHeaderCardResult' : 'UnivariateHeaderCard.UnivariateHeaderCardResult',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard' : 'UnivariateHeaderCard',
    'com.dataiku.dip.coremodel.ObjectCustomMetadata' : 'ObjectCustomMetadata',
    'com.dataiku.dip.eda.compute.computations.univariate.Quantiles' : 'Quantiles',
    'com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantileDesc' : 'Quantiles.QuantileDesc',
    'com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantilesResult' : 'Quantiles.QuantilesResult',
    'com.dataiku.dip.datasets.SamplingParam' : 'SamplingParam',
    'com.dataiku.dip.datasets.SamplingParam$SamplingMethod' : 'SamplingParam.SamplingMethod',
    'com.dataiku.dip.eda.compute.computations.bivariate.Spearman$SpearmanResult' : 'Spearman.SpearmanResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Spearman' : 'Spearman',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard' : 'BivariateBoxPlotCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard$BivariateBoxPlotCardResult' : 'BivariateBoxPlotCard.BivariateBoxPlotCardResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Max' : 'Max',
    'com.dataiku.dip.eda.compute.computations.univariate.Max$MaxResult' : 'Max.MaxResult',
    'com.dataiku.dip.eda.worksheets.cards.Card' : 'Card',
    'com.dataiku.dip.eda.worksheets.cards.FitCurveCard$FitCurveCardResult' : 'FitCurveCard.FitCurveCardResult',
    'com.dataiku.dip.eda.worksheets.cards.FitCurveCard' : 'FitCurveCard',
    'com.dataiku.dip.util.AnyLoc' : 'AnyLoc',
    'com.dataiku.dip.eda.compute.grouping.SubsetGrouping' : 'SubsetGrouping',
    'com.dataiku.dip.eda.compute.grouping.SubsetGrouping$SubsetGroupingResult' : 'SubsetGrouping.SubsetGroupingResult',
    'com.dataiku.dip.eda.compute.computations.FailedResult' : 'FailedResult',
    'com.dataiku.dip.eda.compute.computations.multivariate.FetchValues$FetchValuesResult' : 'FetchValues.FetchValuesResult',
    'com.dataiku.dip.eda.compute.computations.multivariate.FetchValues' : 'FetchValues',
    'com.dataiku.dip.eda.compute.grouping.MergeGrouping$MergeGroupingResult' : 'MergeGrouping.MergeGroupingResult',
    'com.dataiku.dip.eda.compute.grouping.MergeGrouping' : 'MergeGrouping',
    'com.dataiku.dip.eda.compute.computations.univariate.Sem' : 'Sem',
    'com.dataiku.dip.eda.compute.computations.univariate.Sem$SemResult' : 'Sem.SemResult',
    'com.dataiku.dip.eda.compute.filtering.AllFilter' : 'AllFilter',
    'com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp' : 'MoodTestNSamp',
    'com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp$MoodTestNSampResult' : 'MoodTestNSamp.MoodTestNSampResult',
    'com.dataiku.dip.coremodel.RecipeEnginesPreferenceConfig' : 'RecipeEnginesPreferenceConfig',
    'com.dataiku.dip.eda.compute.computations.univariate.FitDistribution$FitDistributionResult' : 'FitDistribution.FitDistributionResult',
    'com.dataiku.dip.eda.compute.computations.univariate.FitDistribution' : 'FitDistribution',
    'com.dataiku.dip.server.services.TaggableObjectsService$TaggableObject' : 'TaggableObjectsService.TaggableObject',
    'com.dataiku.dip.server.services.TaggableObjectsService$TaggableListItem' : 'TaggableObjectsService.TaggableListItem',
    'com.dataiku.dip.server.services.TaggableObjectsService$TaggableObjectRef' : 'TaggableObjectsService.TaggableObjectRef',
    'com.dataiku.dip.eda.EdaErrorCodes' : 'EdaErrorCodes',
    'com.dataiku.dip.server.services.JupyterService$ActiveSession' : 'JupyterService.ActiveSession',
    'com.dataiku.dip.server.services.JupyterService$JupyterNotebookListEntry' : 'JupyterService.JupyterNotebookListEntry',
    'com.dataiku.dip.server.services.JupyterService$DisplayKernelSpec' : 'JupyterService.DisplayKernelSpec',
    'com.dataiku.dip.eda.worksheets.cards.BivariateCard' : 'BivariateCard',
    'com.dataiku.dip.eda.worksheets.cards.TTest2SampCard' : 'TTest2SampCard',
    'com.dataiku.dip.eda.worksheets.cards.TTest2SampCard$TTest2SampCardResult' : 'TTest2SampCard.TTest2SampCardResult',
    'com.dataiku.dip.coremodel.Checklist' : 'Checklist',
    'com.dataiku.dip.coremodel.Checklist$ChecklistItem' : 'Checklist.ChecklistItem',
    'com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard$PairwiseTTestCardResult' : 'PairwiseTTestCard.PairwiseTTestCardResult',
    'com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard' : 'PairwiseTTestCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard' : 'ScatterPlotCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotCardResult' : 'ScatterPlotCard.ScatterPlotCardResult',
    'com.dataiku.dip.eda.compute.distributions.Laplace$FittedLaplace' : 'Laplace.FittedLaplace',
    'com.dataiku.dip.eda.compute.distributions.Laplace' : 'Laplace',
    'com.dataiku.dip.server.services.NavigatorService$SavedModelFullInfo' : 'NavigatorService.SavedModelFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ObjectLink' : 'NavigatorService.ObjectLink',
    'com.dataiku.dip.server.services.NavigatorService$WorksheetFullInfo' : 'NavigatorService.WorksheetFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$WebAppFullInfo' : 'NavigatorService.WebAppFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ComputableFullInfo' : 'NavigatorService.ComputableFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ReportFullInfo' : 'NavigatorService.ReportFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$RecipeFullInfo' : 'NavigatorService.RecipeFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$AnalysisFullInfo' : 'NavigatorService.AnalysisFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$TaggableObjectFullInfo' : 'NavigatorService.TaggableObjectFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ManagedFolderFullInfo' : 'NavigatorService.ManagedFolderFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$DashboardFullInfo' : 'NavigatorService.DashboardFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$NotebookFullInfo' : 'NavigatorService.NotebookFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$InsightFullInfo' : 'NavigatorService.InsightFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$DatasetFullInfo' : 'NavigatorService.DatasetFullInfo',
    'com.dataiku.dip.eda.compute.distributions.Normal$FittedNormal' : 'Normal.FittedNormal',
    'com.dataiku.dip.eda.compute.distributions.Normal' : 'Normal',
    'com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp' : 'TTest1Samp',
    'com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp$TTest1SampResult' : 'TTest1Samp.TTest1SampResult',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotData' : 'MosaicPlotCard.MosaicPlotData',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard' : 'MosaicPlotCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotCardResult' : 'MosaicPlotCard.MosaicPlotCardResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.KendallTau$KendallTauResult' : 'KendallTau.KendallTauResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.KendallTau' : 'KendallTau',
    'com.dataiku.dip.dashboards.model.Tile$TileType' : 'Tile.TileType',
    'com.dataiku.dip.dashboards.model.Tile$TileResizeImageMode' : 'Tile.TileResizeImageMode',
    'com.dataiku.dip.dashboards.model.Tile' : 'Tile',
    'com.dataiku.dip.dashboards.model.Tile$TileClickAction' : 'Tile.TileClickAction',
    'com.dataiku.dip.dashboards.model.Tile$DisplayTitleMode' : 'Tile.DisplayTitleMode',
    'com.dataiku.dip.dashboards.model.Tile$Box' : 'Tile.Box',
    'com.dataiku.dip.dashboards.model.Tile$TileDisplayMode' : 'Tile.TileDisplayMode',
    'com.dataiku.dip.eda.compute.curves.Curve$ParametrizedCurve' : 'Curve.ParametrizedCurve',
    'com.dataiku.dip.eda.compute.curves.Curve' : 'Curve',
    'com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard' : 'TZTest1SampCard',
    'com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard$TZTest1SampCardResult' : 'TZTest1SampCard.TZTest1SampCardResult',
    'com.dataiku.dip.containers.exec.ContainerExecSelection' : 'ContainerExecSelection',
    'com.dataiku.dip.containers.exec.ContainerExecSelection$ContainerExecMode' : 'ContainerExecSelection.ContainerExecMode',
    'com.dataiku.dip.eda.worksheets.models.Worksheet' : 'Worksheet',
    'com.dataiku.dip.dashboards.model.ui.EnrichedDashboardPage$InsightAccessState' : 'EnrichedDashboardPage.InsightAccessState',
    'com.dataiku.dip.eda.compute.computations.univariate.StdDev' : 'StdDev',
    'com.dataiku.dip.eda.compute.computations.univariate.StdDev$StdDevResult' : 'StdDev.StdDevResult',
    'com.dataiku.dip.eda.worksheets.cards.fragments.BoxPlotFragment' : 'BoxPlotFragment',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard' : 'UnivariateFrequencyTableCard',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard$UnivariateFrequencyTableCardResult' : 'UnivariateFrequencyTableCard.UnivariateFrequencyTableCardResult',
    'com.dataiku.dip.eda.compute.computations.common.MultiComputation' : 'MultiComputation',
    'com.dataiku.dip.eda.compute.computations.common.MultiComputation$MultiComputationResult' : 'MultiComputation.MultiComputationResult',
    'com.dataiku.dip.eda.compute.grouping.GroupingResult' : 'GroupingResult',
    'com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard$WorksheetRootCardResult' : 'WorksheetRootCard.WorksheetRootCardResult',
    'com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard' : 'WorksheetRootCard',
    'com.dataiku.dip.eda.compute.computations.univariate.ZTest1Samp$ZTest1SampResult' : 'ZTest1Samp.ZTest1SampResult',
    'com.dataiku.dip.eda.compute.computations.univariate.ZTest1Samp' : 'ZTest1Samp',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractPairwiseTestCard' : 'AbstractPairwiseTestCard',
    'com.dataiku.dip.dashboards.model.TilesGrid' : 'TilesGrid',
    'com.dataiku.dip.eda.worksheets.models.SplitBySpec' : 'SplitBySpec',
    'com.dataiku.dip.eda.compute.distributions.Weibull' : 'Weibull',
    'com.dataiku.dip.eda.compute.distributions.Weibull$FittedWeibull' : 'Weibull.FittedWeibull',
    'com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard' : 'Abstract2SampTestCard',
    'com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard$Abstract2SampTestCardResult' : 'Abstract2SampTestCard.Abstract2SampTestCardResult',
    'com.dataiku.dip.datasets.StreamableDatasetSelection' : 'StreamableDatasetSelection',
    'com.dataiku.dip.eda.compute.computations.bivariate.Pearson' : 'Pearson',
    'com.dataiku.dip.eda.compute.computations.bivariate.Pearson$PearsonResult' : 'Pearson.PearsonResult',
    'com.dataiku.dip.eda.compute.computations.common.DummyComputation$DummyComputationResult' : 'DummyComputation.DummyComputationResult',
    'com.dataiku.dip.eda.compute.computations.common.DummyComputation' : 'DummyComputation',
    'com.dataiku.dip.eda.worksheets.cards.FitDistributionCard' : 'FitDistributionCard',
    'com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$RequestedDistribution' : 'FitDistributionCard.RequestedDistribution',
    'com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$SingleFitResult' : 'FitDistributionCard.SingleFitResult',
    'com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$FitDistributionCardResult' : 'FitDistributionCard.FitDistributionCardResult',
    'com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult' : 'InteractiveQuery.InteractiveQueryResult',
    'com.dataiku.dip.eda.worksheets.queries.InteractiveQuery' : 'InteractiveQuery',
    'com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard$KsTest2SampCardResult' : 'KsTest2SampCard.KsTest2SampCardResult',
    'com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard' : 'KsTest2SampCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard$BivariateHeaderCardResult' : 'BivariateHeaderCard.BivariateHeaderCardResult',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard' : 'BivariateHeaderCard',
    'com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard' : 'MoodTest2SampCard',
    'com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard$MoodTest2SampCardResult' : 'MoodTest2SampCard.MoodTest2SampCardResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Mean' : 'Mean',
    'com.dataiku.dip.eda.compute.computations.univariate.Mean$MeanResult' : 'Mean.MeanResult',
    'com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard' : 'PairwiseMoodTestCard',
    'com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard$PairwiseMoodTestCardResult' : 'PairwiseMoodTestCard.PairwiseMoodTestCardResult',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.Abstract2DPivotCard' : 'Abstract2DPivotCard',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$StatsToCompute' : 'UnivariateSummaryStatsCard.StatsToCompute',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$UnivariateSummaryStatsCardResult' : 'UnivariateSummaryStatsCard.UnivariateSummaryStatsCardResult',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard' : 'UnivariateSummaryStatsCard',
    'com.dataiku.dip.eda.compute.grouping.Grouping' : 'Grouping',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer' : 'AbstractCardContainer',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer$AbstractCardContainerResult' : 'AbstractCardContainer.AbstractCardContainerResult',
    'com.dataiku.dip.eda.compute.distributions.Pareto' : 'Pareto',
    'com.dataiku.dip.eda.compute.distributions.Pareto$FittedPareto' : 'Pareto.FittedPareto',
    'com.dataiku.dip.dashboards.model.Insight' : 'Insight',
    'com.dataiku.dip.dashboards.model.Insight$InsightListItem' : 'Insight.InsightListItem',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard' : 'AbstractNSampTestCard',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$AbstractNSampleTestCardResult' : 'AbstractNSampTestCard.AbstractNSampleTestCardResult',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$GroupInfo' : 'AbstractNSampTestCard.GroupInfo',
    'com.dataiku.dip.server.services.DatasetNotebookService$NotebookType' : 'DatasetNotebookService.NotebookType',
    'com.dataiku.dip.server.services.DatasetNotebookService$SimpleNotebook' : 'DatasetNotebookService.SimpleNotebook',
    'com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinningMode' : 'BinnedGrouping.BinningMode',
    'com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinnedGroupingResult' : 'BinnedGrouping.BinnedGroupingResult',
    'com.dataiku.dip.eda.compute.grouping.BinnedGrouping' : 'BinnedGrouping',
    'com.dataiku.dip.eda.worksheets.cards.common.ColumnCard$ColumnCardResult' : 'ColumnCard.ColumnCardResult',
    'com.dataiku.dip.eda.worksheets.cards.common.ColumnCard' : 'ColumnCard',
    'com.dataiku.dip.server.controllers.ProjectsCRUDController$ProjectHead' : 'ProjectsCRUDController.ProjectHead',
    'com.dataiku.dip.datasets.DatasetSelection' : 'DatasetSelection',
    'com.dataiku.dip.datasets.DatasetSelection$PartitionSelectionMethod' : 'DatasetSelection.PartitionSelectionMethod',
    'com.dataiku.dip.eda.compute.computations.Computation$UnivariateComputation' : 'Computation.UnivariateComputation',
    'com.dataiku.dip.eda.compute.computations.Computation$BivariateComputation' : 'Computation.BivariateComputation',
    'com.dataiku.dip.eda.compute.computations.Computation' : 'Computation',
    'com.dataiku.dip.eda.compute.computations.Computation$MultivariateComputation' : 'Computation.MultivariateComputation',
    'com.dataiku.dip.eda.compute.utils.CompactedArray' : 'CompactedArray',
    'com.dataiku.dip.coremodel.SchemaColumn' : 'SchemaColumn'

};
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/chi2-ind-test-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/joint-normal';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/quantiles-table-card';
export * from './src/main/java/com/dataiku/dip/coremodel/checklists';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/fit2-ddistribution';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/skewness';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/binomial';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/one-way-anova';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/summary-card-builder';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-frequency-table-card';
export * from './src/main/java/com/dataiku/dip/dashboards/model/dashboard';
export * from './dss-core/src/main/java/com/dataiku/dip/input/dataset-ordering';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/covariance';
export * from './src/main/java/com/dataiku/dip/coremodel/serialized-recipe';
export * from './src/main/java/com/dataiku/dip/server/datasets/dataset-metrics-status-service';
export * from './src/main/java/com/dataiku/dip/server/datasets/datasets-crudcontroller';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/anum-filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/shapiro';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/not-computed-result';
export * from './src/main/java/com/dataiku/dip/eda/compute/sampling/data-spec';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/cross-grouping';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/queries/list-most-frequent-values';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/test-distribution';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-histogram-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/and-filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/poisson';
export * from './src/main/java/com/dataiku/dip/server/services/projects-service';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/numerical-histogram-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/curves/polynomial-curve';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/models/variable';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/sign-test1-samp';
export * from './dss-core/src/main/java/com/dataiku/dip/partitioning/dimension';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/normal-mixture';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/multivariate-card';
export * from './src/main/java/com/dataiku/dip/datasets/dataset-selection-to-mem-table';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/interval-filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/missing-value-filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/count-groups';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/computation-result';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/chi2-ind-test';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/numerical-histogram-fragment';
export * from './src/main/java/com/dataiku/dip/dashboards/model/dashboard-page';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/sum';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/variance';
export * from './dss-core/src/main/java/com/dataiku/dip/partitioning/exact-value-dimension';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/worksheets-service';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/heatmap-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/kurtosis';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/kde2-d';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/min';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/distribution2-d';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/unavailable-result';
export * from './src/main/java/com/dataiku/dip/server/services/read-only-jobs-internal-db';
export * from './src/main/java/com/dataiku/dip/eda/compute/stats/pvalue-adjustment-method';
export * from './dss-core/src/main/java/com/dataiku/dip/partitioning/time-dimension';
export * from './src/main/java/com/dataiku/dip/server/services/itagging-service';
export * from './dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/grouped-compiled-card-wrapper';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/anum-grouping';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/triangular';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/count-complete-fragment';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/mutual-information';
export * from './src/main/java/com/dataiku/dip/coremodel/exposed-object';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/grouped-computation';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/ks-test2-samp';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/multivariate/pca';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/mood-test-nsamp-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/pcacard';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/categorical-histogram-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/log-normal';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/pairwise-mood-test';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/distribution';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fit2-ddistribution-card';
export * from './src/main/java/com/dataiku/dip/server/services/explores-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/exponential';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/beta';
export * from './src/main/java/com/dataiku/dip/coremodel/serialized-project';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-summary-stats-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/sign-test1-samp-card';
export * from './src/main/java/com/dataiku/dip/server/usability-computer';
export * from './src/main/java/com/dataiku/dip/eda/compute/curves/isotonic-curve';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/pairwise-ttest';
export * from './src/main/java/com/dataiku/dip/eda/compute/sampling/sample';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/bivariate-frequency-table-fragment';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/correlation-matrix-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/card-result';
export * from './dss-core/src/main/java/com/dataiku/dip/coremodel/schema';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/one-way-anovacard';
export * from './src/main/java/com/dataiku/dip/coremodel/serialized-dataset';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract-header-card';
export * from './src/main/java/com/dataiku/dip/coremodel/version-tag';
export * from './src/main/java/com/dataiku/dip/server/controllers/jupyter-controller';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/fit-curve';
export * from './src/main/java/com/dataiku/dip/coremodel/head-with-versioning-info';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/empirical-cdf';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/not-filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/available-result';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/models/binning-mode';
export * from './dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/count';
export * from './dss-core/src/main/java/com/dataiku/dip/datasets/type';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/union-grouping';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/shapiro-normality-test-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/filter';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/univariate-header-card';
export * from './src/main/java/com/dataiku/dip/coremodel/object-custom-metadata';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/quantiles';
export * from './src/main/java/com/dataiku/dip/datasets/sampling-param';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/spearman';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-box-plot-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/max';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fit-curve-card';
export * from './src/main/java/com/dataiku/dip/util/any-loc';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/subset-grouping';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/failed-result';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/multivariate/fetch-values';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/merge-grouping';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/sem';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/all-filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/mood-test-nsamp';
export * from './src/main/java/com/dataiku/dip/coremodel/recipe-engines-preference-config';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/fit-distribution';
export * from './src/main/java/com/dataiku/dip/server/services/taggable-objects-service';
export * from './src/main/java/com/dataiku/dip/eda/eda-error-codes';
export * from './src/main/java/com/dataiku/dip/server/services/jupyter-service';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/ttest2-samp-card';
export * from './src/main/java/com/dataiku/dip/coremodel/checklist';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/pairwise-ttest-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/scatter-plot-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/laplace';
export * from './src/main/java/com/dataiku/dip/server/services/navigator-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/normal';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/ttest1-samp';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/mosaic-plot-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/kendall-tau';
export * from './src/main/java/com/dataiku/dip/dashboards/model/tile';
export * from './src/main/java/com/dataiku/dip/eda/compute/curves/curve';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/tztest1-samp-card';
export * from './src/main/java/com/dataiku/dip/containers/exec/container-exec-selection';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/models/worksheet';
export * from './src/main/java/com/dataiku/dip/dashboards/model/ui/enriched-dashboard-page';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/std-dev';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/box-plot-fragment';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/univariate-frequency-table-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/multi-computation';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/grouping-result';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/worksheet-root-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/ztest1-samp';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract-pairwise-test-card';
export * from './src/main/java/com/dataiku/dip/dashboards/model/tiles-grid';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/models/split-by-spec';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/weibull';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract2-samp-test-card';
export * from './src/main/java/com/dataiku/dip/datasets/streamable-dataset-selection';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/pearson';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/dummy-computation';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fit-distribution-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/queries/interactive-query';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/ks-test2-samp-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-header-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/mood-test2-samp-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/mean';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/pairwise-mood-test-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/abstract2-dpivot-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/univariate-summary-stats-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/grouping';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract-card-container';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/pareto';
export * from './src/main/java/com/dataiku/dip/dashboards/model/insight';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract-nsamp-test-card';
export * from './src/main/java/com/dataiku/dip/server/services/dataset-notebook-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/binned-grouping';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/column-card';
export * from './src/main/java/com/dataiku/dip/server/controllers/projects-crudcontroller';
export * from './src/main/java/com/dataiku/dip/datasets/dataset-selection';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/computation';
export * from './src/main/java/com/dataiku/dip/eda/compute/utils/compacted-array';
export * from './dss-core/src/main/java/com/dataiku/dip/coremodel/schema-column';