import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import _ from 'lodash';
import { UnivariateHeaderCard, Variable } from 'src/generated-sources';
import { Observable, combineLatest } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { map, startWith, tap } from 'rxjs/operators';
import { CardWizardVariable } from '@features/eda/card-models';
import { minCheckedValidator } from '@utils/min-checked-validator';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { MAX_HEADER_COLUMNS, CANNOT_ADD_REASON, identicalVariableNames, unselectVariables } from '@features/eda/card-utils';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { WT1Service } from '@core/dataiku-wt1/wt1.service';

@UntilDestroy()
@Component({
    selector: 'univariate-header-card-config',
    templateUrl: './univariate-header-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './univariate-header-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnivariateHeaderCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: UnivariateHeaderCard ;
    @Output() paramsChange = new EventEmitter<UnivariateHeaderCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    allVariables$: Observable<CardWizardVariable[]>;
    xColumns$: Observable<Variable[]>;
    currentVariables$: Observable<CardWizardVariable[]>;
    selectedAvailableVariables = [];
    selectedSelectedVariables = [];
    count = 0;

    options = [{
        name: 'Histogram',
        id: 'showHistogram',
        variableTypes: [{
            x: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CATEGORICAL
        }]
    }, {
        name: 'Quantile table',
        id: 'showQuantile',
        variableTypes: [{
            x: Variable.Type.CONTINUOUS
        }]
    }, {
        name: 'Frequency table',
        id: 'showFrequencyTable',
        variableTypes: [{
            x: Variable.Type.CATEGORICAL
        }]
    }, {
        name: 'Summary Stats',
        id: 'showSummary',
        variableTypes: [{
            x: Variable.Type.CONTINUOUS
        }, {
            x: Variable.Type.CATEGORICAL
        }]
    }, {
        name: 'Box Plot',
        id: 'showBoxPlot',
        variableTypes: [{
            x: Variable.Type.CONTINUOUS
        }]
    }];

    configForm = this.fb.group({
        xColumns: this.fb.control([], [Validators.required]),
        showBoxPlot: this.fb.control(true, [Validators.required]),
        showFrequencyTable: this.fb.control(true, [Validators.required]),
        showHistogram: this.fb.control(true, [Validators.required]),
        showQuantile: this.fb.control(true, [Validators.required]),
        showSummary: this.fb.control(true, [Validators.required]),
    }, { validator: minCheckedValidator(this.options.map(o => o.id)) });

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService,
        private wt1Service: WT1Service
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => this.paramsChange.emit({
                ...this.params,
                xColumns: formValue.xColumns,
                showBoxPlot: formValue.showBoxPlot,
                showFrequencyTable: formValue.showFrequencyTable,
                showHistogram: formValue.showHistogram,
                showQuantile: formValue.showQuantile,
                showSummary: formValue.showSummary
            }));

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        const xColumns = this.configForm.controls.xColumns.value as Variable[]; // need starting value for combineLatest
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
        this.xColumns$ = this.configForm.controls.xColumns.valueChanges;

        this.currentVariables$ = combineLatest([this.allVariables$, this.xColumns$.pipe(startWith(xColumns))])
            .pipe(
                map(([all, x]) => {
                    // remove x variables from current variable list
                    return all.filter(variable => x.findIndex(xVariable => xVariable.name === variable.name) < 0).map(({selected, ...attrs}) => attrs);
                }),
                tap(() => {
                    this.selectedSelectedVariables = [];
                    this.selectedSelectedVariables = [];
                })
            );

        this.currentVariables$
            .pipe(untilDestroyed(this))
            .subscribe(variables => {
                this.count = variables.length;
            });

        this.xColumns$
            .pipe(untilDestroyed(this))
            .subscribe((columns) => this.toggleOptions(columns));
    }

    toggleOptions(columns: Variable[]) {
        // get what variable types have already been added: either continuous or categorical.
        const addedTypes = columns.map((v: Variable) => v.type).filter((v: Variable.Type, i: number, arr: Variable.Type[]) => arr.indexOf(v) === i);

        this.options.forEach((option: any) => {
            option.enabled = !!option.variableTypes.filter((type: any) => addedTypes.includes(type.x)).length;

            if (option.enabled) {
                this.configForm.get(option.id)!.enable();
            } else {
                this.configForm.get(option.id)!.disable();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                xColumns: this.params.xColumns,
                showBoxPlot: this.params.showBoxPlot,
                showFrequencyTable: this.params.showFrequencyTable,
                showHistogram: this.params.showHistogram,
                showQuantile: this.params.showQuantile,
                showSummary: this.params.showSummary
            });

            this.toggleOptions(this.params.xColumns);
        }
    }

    add() {
        this.configForm.patchValue({
            xColumns: unselectVariables(this.configForm.controls.xColumns.value.concat(this.selectedAvailableVariables))
        });

        this.selectedAvailableVariables = [];
        this.selectedSelectedVariables = [];
    }

    del() {
        const value = this.configForm.controls.xColumns.value;

        this.configForm.patchValue({
            xColumns: Array.isArray(value) ? _.differenceWith(value, this.selectedSelectedVariables, identicalVariableNames) : []
        });

        this.selectedAvailableVariables = [];
        this.selectedSelectedVariables = [];
    }

    get canAdd() {
        return this.selectedAvailableVariables.length > 0 && (this.configForm.controls.xColumns.value.length + this.selectedAvailableVariables.length <= MAX_HEADER_COLUMNS);
    }

    get disabledReason() {
        if (this.configForm.controls.xColumns.value.length + this.selectedAvailableVariables.length > MAX_HEADER_COLUMNS) {
            return CANNOT_ADD_REASON.MAX_VARIABLES_EXCEEDED;
        } else if (this.selectedAvailableVariables.length <= 0) {
            return CANNOT_ADD_REASON.NO_VARIABLE_SELECTED;
        }

        return '';
    }

    ngOnDestroy() {

    }

    onDropAdd(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);
        const currentValue = this.configForm.controls.xColumns.value;
        let newValue: CardWizardVariable[];
        if (currentValue) {
            let spliceIndex = dropped.currentIndex;
            newValue = _.cloneDeep(currentValue);
            if (dropped.container === dropped.previousContainer) {
                const indexes = droppedItems.map(d => newValue.findIndex(e => e.name === d.name ));
                indexes.splice(0, 1);
                const offset = _.sumBy(indexes, curIndex => curIndex <= spliceIndex ? 1 : 0);
                spliceIndex -= offset;
                newValue = _.differenceWith(newValue, droppedItems, identicalVariableNames);
            }
            newValue.splice(spliceIndex, 0, ...droppedItems);
        } else {
            newValue = droppedItems;
        }
        this.configForm.patchValue({
            xColumns: unselectVariables(newValue)
        });
        this.selectedAvailableVariables = [];
        this.selectedSelectedVariables = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }

    onDropVariables(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);

        const currentXValue = this.configForm.controls.xColumns.value;
        const newXValue = currentXValue ? _.differenceWith(currentXValue, droppedItems, identicalVariableNames) : currentXValue;

        this.configForm.patchValue({
            xColumns: unselectVariables(newXValue)
        });
        this.selectedAvailableVariables = [];
        this.selectedSelectedVariables = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }
}
