<div class="list-box__search flex std-list-search-box with-icon" style="flex: 0;">
    <div class="std-list-search-box list-control-widget" style="margin: 0">
        <span class="add-on">
            <i class="icon-dku-search"></i>
        </span>
        <input type="search" class="list__search-input" placeholder="Search" [(ngModel)]="query" >
    </div>
</div>
<cdk-virtual-scroll-viewport [itemSize]="38" class="list-box__main" [qa]="'eda-available-variables'">
    <ul class="list-box__items" cdkDropList [cdkDropListDisabled]="!dragDropable"
        [cdkDropListSortingDisabled]="!sortDraggable" (cdkDropListDropped)="drop($event)"
    >
        <li class="drag-drop-placeholder--invisible" *ngIf="currentList.length === 0"><i class="list-box__icon"></i></li>
        <ng-container *cdkVirtualFor="let item of currentList | filter: query:'name'; trackBy: trackByVariableName">
            <li class="list-box__item" [ngClass]="{ 'list-box__item--selected': item.selected, 'list-box__item--disabled': item.disabled }"
                cdkDrag
                [cdkDragDisabled]="item.disabled"
                (cdkDragStarted)="dragStarted($event)"
                [cdkDragData]="item"
                [matTooltip]="item.disabled ? disabledMessage : ''"
            >
                <a class="list-box__link" (click)="selectItem($event, item)"
                    [qa]="{'eda-variable': {name: item.name, type: item.type}}">
                    <i class="list-box__icon" [ngClass]="{ 'icon-font': item.type === Variable.Type.CATEGORICAL, 'icon-number': item.type === Variable.Type.CONTINUOUS }" ></i>
                    {{ item.name }}
                </a>
                <div class="drag-drop-placeholder" *cdkDragPlaceholder><i class="list-box__icon" [ngClass]="{ 'icon-font': item.type === Variable.Type.CATEGORICAL, 'icon-number': item.type === Variable.Type.CONTINUOUS }" ></i>Variable(s)</div>
                <div class="drag-drop-preview" *cdkDragPreview><i class="list-box__icon" [ngClass]="{ 'icon-font': item.type === Variable.Type.CATEGORICAL, 'icon-number': item.type === Variable.Type.CONTINUOUS }"></i>
                        Variable(s)
                </div>
            </li>
        </ng-container>
    </ul>
</cdk-virtual-scroll-viewport>
