<div class="card-layout">
    <div class="card-layout__section card-layout__section--vertical">
        <div class="card-layout__section card-layout__section--horizontal">
            <div class="card-layout__section">
                <div class="card-layout__title">Eigenvalues</div>
                <div class="card-layout__content">
                    <lazy-render
                        class="variance-chart"
                        [visibleTemplate]="visibleVariance"
                    >
                        <ng-template #visibleVariance>
                            <div
                                echarts
                                [options]="varianceChartOptions"
                            ></div>
                        </ng-template>
                    </lazy-render>

                </div>
            </div>
            <div class="card-layout__section">
                <div class="card-layout__title">Scatter plot</div>
                <div class="card-layout__content">
                    <lazy-render
                        class="scatter-chart"
                        [visibleTemplate]="visibleScatterPlot"
                    >
                        <ng-template #visibleScatterPlot>
                            <div
                                echarts
                                [options]="scatterPlotChartOptions"
                            ></div>
                        </ng-template>
                    </lazy-render>

                </div>
            </div>
        </div>
    </div>
    <div class="card-layout__section">
        <div class="card-layout__title">
            Principal components
            <button
                *ngIf="!readOnly"
                class="btn btn--icon menu-button pull-right"
                [matMenuTriggerFor]="contextualMenu"
            >
                <i class="icon-dku-ellipsis-vertical"></i>
            </button>

            <mat-menu #contextualMenu>
                <button
                    mat-menu-item
                    (click)="configureVisualization()"
                >
                    Configure heatmap...
                </button>
            </mat-menu>
        </div>
        <div class="card-layout__content">
            <heatmap
                [xLabels]="heatmapEVLabels"
                [yLabels]="heatmapColumnLabels"
                [data]="heatmapData"
                [readOnly]="readOnly"
                [heatmapParams]="params.heatmapParams"
            ></heatmap>
        </div>
    </div>
</div>
