import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import _ from 'lodash';
import { MultivariateCard, Variable, CorrelationMatrixCard, isCorrelationMatrixCard } from 'src/generated-sources';
import { Observable, combineLatest } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { map, startWith, tap } from 'rxjs/operators';
import { CardWizardVariable } from '@features/eda/card-models';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { MAX_HEADER_COLUMNS, CANNOT_ADD_REASON, identicalVariableNames, unselectVariables } from '@features/eda/card-utils';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WT1Service } from '@core/dataiku-wt1/wt1.service';

@UntilDestroy()
@Component({
    selector: 'multivariate-card-config',
    templateUrl: './multivariate-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './multivariate-card-config.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultivariateCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: MultivariateCard;
    @Input() variables: CardWizardVariable[];
    @Output() paramsChange = new EventEmitter<MultivariateCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);
    CorrelationMetric = CorrelationMatrixCard.CorrelationMetric;
    isCorrelationMatrixCard = isCorrelationMatrixCard;

    allVariables$: Observable<CardWizardVariable[]>;
    columns$: Observable<Variable[]>;
    currentVariables$: Observable<CardWizardVariable[]>;
    selectedAvailableVariables = [];
    selectedSelectedVariables = [];
    count = 0;

    configForm =  this.fb.group({
        columns: this.fb.control([], [Validators.required, Validators.minLength(1)])
    });

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService,
        private wt1Service: WT1Service
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => {
                const changedParams: MultivariateCard = {
                    ...this.params,
                    columns: formValue.columns,
                };

                if (isCorrelationMatrixCard(this.params)) {
                    (changedParams as CorrelationMatrixCard).metric = formValue.metric;
                }

                this.paramsChange.emit(changedParams);
            });

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        const columns = this.configForm.controls.columns.value as Variable[]; // need starting value for combineLatest
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
        this.columns$ = this.configForm.controls.columns.valueChanges;

        this.currentVariables$ = combineLatest([this.allVariables$, this.columns$.pipe(startWith(columns))])
            .pipe(
                map(([all, column]) => {
                    // remove x variables from current variable list
                    return all.filter(variable => column.findIndex(columnVariable => columnVariable.name === variable.name) < 0).map(({selected, ...attrs}) => attrs);
                }),
                tap(() => {
                    this.selectedSelectedVariables = [];
                    this.selectedSelectedVariables = [];
                })
            );

        this.currentVariables$
            .pipe(untilDestroyed(this))
            .subscribe(variables => {
                this.count = variables.filter(v => !v.disabled).length;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            const patch = {
                columns: this.params.columns
            };

            if (isCorrelationMatrixCard(this.params)) {
                if (!this.configForm.controls.metric) {
                    this.configForm.addControl('metric', this.fb.control(CorrelationMatrixCard.CorrelationMetric.SPEARMAN, [Validators.required]));
                }
                (patch as CorrelationMatrixCard).metric = this.params.metric;
            }

            this.configForm.patchValue(patch);
        }
    }

    add() {
        this.configForm.patchValue({
            columns: unselectVariables(this.configForm.controls.columns.value.concat(this.selectedAvailableVariables))
        });

        this.selectedAvailableVariables = [];
    }

    del() {
        const value = this.configForm.controls.columns.value;

        this.configForm.patchValue({
            columns: Array.isArray(value) ? _.differenceWith(value, this.selectedSelectedVariables, identicalVariableNames) : []
        });

        this.selectedSelectedVariables = [];
    }

    get canAdd() {
        return this.selectedAvailableVariables.length > 0 && (this.configForm.controls.columns.value.length + this.selectedAvailableVariables.length <= MAX_HEADER_COLUMNS);
    }

    get disabledReason() {
        if (this.configForm.controls.columns.value.length + this.selectedAvailableVariables.length > MAX_HEADER_COLUMNS) {
            return CANNOT_ADD_REASON.MAX_VARIABLES_EXCEEDED;
        } else if (this.selectedAvailableVariables.length <= 0) {
            return CANNOT_ADD_REASON.NO_VARIABLE_SELECTED;
        }

        return '';
    }

    ngOnDestroy() {

    }

    onDropAdd(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);
        const currentValue = this.configForm.controls.columns.value;
        let newValue: CardWizardVariable[];
        if (currentValue) {
            let spliceIndex = dropped.currentIndex;
            newValue = _.cloneDeep(currentValue);
            if (dropped.container === dropped.previousContainer) {
                const indexes = droppedItems.map(d => newValue.findIndex(e => e.name === d.name ));
                indexes.splice(0, 1);
                const offset = _.sumBy(indexes, curIndex => curIndex <= spliceIndex ? 1 : 0);
                spliceIndex -= offset;
                newValue = _.differenceWith(newValue, droppedItems, identicalVariableNames);
            }
            newValue.splice(spliceIndex, 0, ...droppedItems);
        } else {
            newValue = droppedItems;
        }
        this.configForm.patchValue({
            columns: unselectVariables(newValue)
        });
        this.selectedAvailableVariables = [];
        this.selectedSelectedVariables = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }

    onDropVariables(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);

        const currentValue = this.configForm.controls.columns.value;
        const newValue = currentValue ? _.differenceWith(currentValue, droppedItems, identicalVariableNames) : currentValue;

        this.configForm.patchValue({
            columns: unselectVariables(newValue)
        });
        this.selectedAvailableVariables = [];
        this.selectedSelectedVariables = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }
}
