// In this file, we make Angular components available to AngularJS
//
// - ! - WARNING - ! -
//
// - You have to redeclare the selector for directives, with the AngularJS convention:
// ex: for myDirective to match my-directive markup.
// The selector metadata of the downgraded Angular component is ignored.
// For simplicity, make sure what you write corresponds to the component definition,
// In the Component file:
//     selector: 'my-directive',
// here:
//  angular.module(...).directive('myDirective', ...)

import {downgradeComponent} from '@angular/upgrade/static';
import {fairAny} from 'dku-frontend-core';
import {Type} from '@angular/core';

import { EdaComponent } from '@features/eda/eda.component';
import { InsightCardComponent } from '@features/eda/worksheet/cards/insight-card/insight-card.component';
import { WorksheetRedirectionPageComponent } from '@features/eda/pages/worksheet-redirection-page/worksheet-redirection-page.component';

function directive(ajsDirective: string, component: Type<any>, inputs?: string[], outputs?: string[]): Type<any> {
  const angularJS = (window as fairAny).angular;
  angularJS.module('dataiku').directive(ajsDirective, downgradeComponent({component, inputs, outputs, propagateDigest: false}));
  return component;
}

export function downgradeComponents(): Array<Type<any>> {
  return [
    directive('ng2Eda', EdaComponent),
    directive('ng2EdaWorksheetRedirectionPage', WorksheetRedirectionPageComponent),
    directive('ng2EdaInsight', InsightCardComponent, ['insight', 'readOnly'], ['updated'])
  ];
}
