import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeatmapParams } from '@model-main/eda/worksheets/cards/common/heatmap-params';
import _ from 'lodash';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'heatmap-config',
    templateUrl: './heatmap-config.component.html',
    styleUrls: [
        '../../../../shared-styles/forms.less',
        './heatmap-config.component.less'
    ]
})
export class HeatmapConfigComponent {
    configForm = this.fb.group({
        showAbsValues: this.fb.control(null, [Validators.required]),
        showColors: this.fb.control(null, [Validators.required]),
        showValues: this.fb.control(null, [Validators.required]),
        threshold: this.fb.control(null, [Validators.min(0), Validators.max(1)])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<HeatmapConfigComponent>,
        private fb: FormBuilder
    ) {
        this.configForm.patchValue({
            showAbsValues: data.params.showAbsValues,
            showColors: data.params.showColors,
            showValues: data.params.showValues,
            threshold: data.params.threshold
        });
    }

    dismiss() {
        this.dialogRef.close(undefined);
    }

    finish() {
        this.dialogRef.close({
            showAbsValues: this.configForm.value.showAbsValues,
            showColors: this.configForm.value.showColors,
            showValues: this.configForm.value.showValues,
            threshold: this.configForm.value.threshold
        });
    }
}
