import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ViewContainerRef, SimpleChanges, OnChanges } from '@angular/core';
import { CardActionType, CardAction } from '@features/eda/worksheet/cards/events';
import { Observable } from 'rxjs';
import { Worksheet, WorksheetRootCard, DataSpec, Card, AnyLoc, WorksheetsService, isWorksheetRootCard } from 'src/generated-sources';
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { map, filter, distinctUntilChanged, take } from 'rxjs/operators';
import { NewCardModalComponent } from './card-wizard/new-card-modal/new-card-modal.component';
import { EdaService } from '../eda.service';
import { CollapsingService, LocalStorageCollapsingService } from '../collapsing.service';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { getFullCardTitle } from '../card-utils';
import { DuplicateWorksheetDialogComponent } from './duplicate-worksheet-dialog/duplicate-worksheet-dialog.component';
import { RenameWorksheetDialogComponent } from './rename-worksheet-dialog/rename-worksheet-dialog.component';
import { SampleContextService } from '../sample-context.service';
import { WorksheetContextService } from '../worksheet-state/worksheet.context.service';
import { clearHighlightFilter } from '../utils';

@UntilDestroy()
@Component({
    selector: 'worksheet',
    templateUrl: './worksheet.component.html',
    styleUrls: [
        '../shared-styles/header-button.less',
        './worksheet.component.less'
    ],
    providers: [WorksheetContextService,
        {
            provide: SampleContextService,
            useExisting: WorksheetContextService
        },
        {
            provide: CollapsingService,
            useClass: LocalStorageCollapsingService,
        }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorksheetComponent implements OnChanges, OnInit, OnDestroy {
    @Input() worksheetLoc: AnyLoc;
    @Input() readOnly = true;

    // EDA-wide
    worksheets$: Observable<WorksheetsService.WorksheetHead[]>;
    datasetFullInfo$ = this.edaService.getDatasetFullInfo();
    error$ = this.edaService.getError();

    // Worksheet-wide
    worksheet$: Observable<Worksheet | undefined>;
    dataSpec$: Observable<DataSpec | undefined>;
    rootCardParams$: Observable<WorksheetRootCard | undefined>;
    rootCardResults$: Observable<WorksheetRootCard.WorksheetRootCardResult | undefined>;

    constructor(
        private modalsService: ModalsService,
        private edaService: EdaService,
        private worksheetContextService: WorksheetContextService,
        private dialog: MatDialog,
        public viewContainerRef: ViewContainerRef
    ) {
        this.worksheets$ = this.edaService.listWorksheets().pipe(map(worksheets=> worksheets || []));
        this.dataSpec$ = worksheetContextService.getDataSpec();
        this.rootCardParams$ = worksheetContextService.getRootCard();
        this.rootCardResults$ = worksheetContextService.getRootCardResults();
        this.worksheet$ = worksheetContextService.getWorksheet();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.worksheetLoc) {
            this.worksheetContextService.loadWorksheet(
                this.worksheetLoc.projectKey,
                this.worksheetLoc.id
            );
        }
    }

    ngOnInit() {
        this.worksheetContextService.getWorksheet().pipe(
            map(worksheet => !!worksheet && worksheet.rootCard!.cards.length === 0),
            filter(hasNoCard => hasNoCard),
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe(() => this.openAnalysisModal());
        this.worksheetContextService.getError().subscribe(
            e => this.edaService.pushError(e)
        );
    }

    handleRootCardAction(action: CardAction) {
        if (this.readOnly) {
            return;
        }

        if (action.type === CardActionType.UPDATE && isWorksheetRootCard(action.newParams)) {
            this.worksheetContextService.editRootCard(action.newParams);
        } else if (action.type === CardActionType.UPDATE && isWorksheetRootCard(action.newParams)) {
            this.worksheetContextService.editRootCard(action.newParams, action.immediate);
        } else if (CardActionType.REORDER_HEADER_CARD === action.type && !this.readOnly) {
            this.worksheetContextService.swapHeaderCardColumns(action.cardId, action.previousIndex, action.currentIndex);
        } else if (CardActionType.PUBLISH === action.type) {
            this.worksheetContextService.createInsight(
                clearHighlightFilter(action.card),
                getFullCardTitle(action.card)
            );
        } else if (CardActionType.ADD === action.type && !this.readOnly) {
            this.addCard(action.card);
        }
    }

    resetError() {
        this.worksheetContextService.pushError();
    }

    updateDataSpec(newDataSpec: DataSpec) {
        this.worksheetContextService.editDataSpec(newDataSpec);
    }

    addCard(card: Card): void {
        this.worksheetContextService.addTopLevelCard(card);
    }

    openAnalysisModal() {
        this.modalsService.open(NewCardModalComponent,
            { mode: 'NEW' }, ModalShape.NONE, this.viewContainerRef
        ).then(({ card }) => {
            this.addCard(card);
        }, () => { });
    }

    createWorksheet() {
        this.edaService.newWorksheet();
    }

    renameWorksheet() {
        this.worksheet$.pipe(take(1)).subscribe(worksheet => {
            if (worksheet) {
                this.modalsService.open(RenameWorksheetDialogComponent,
                    { name: worksheet.name }, ModalShape.NARROW, this.viewContainerRef
                ).then((newName: string) => {
                    this.worksheetContextService.editWorksheetName(newName);
                }, () => { });
            }
        });
    }

    duplicateWorksheet() {
        this.modalsService.open(DuplicateWorksheetDialogComponent,
            {}, ModalShape.NARROW, this.viewContainerRef
        ).then(({ projectKey, datasetName }) => {
            this.worksheet$.pipe(take(1)).subscribe(worksheet => {
                if (worksheet) {
                    this.edaService.duplicateWorksheet(projectKey, datasetName, worksheet.rootCard!, worksheet.name || '');
                }
            });
        }, () => { });
    }

    deleteWorksheet() {
        this.worksheet$.pipe(take(1)).subscribe(worksheet => {
            if (worksheet) {
                const dialogRef = this.dialog.open(DeleteDialogComponent, {
                    data: { title: worksheet.name },
                    restoreFocus: false,
                    panelClass: ['modal', 'modal3', 'dku-modal-panel-narrow']
                });

                dialogRef.afterClosed().subscribe(() => {
                    if (worksheet) {
                        this.edaService.deleteWorksheet(worksheet);
                    }
                });
            }
        });
    }

    changeWorksheet(id: string) {
        this.edaService.changeWorksheetId(id);
    }

    ngOnDestroy() {
    }
}
