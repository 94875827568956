<lazy-render
    [trackData]="results"
    [visibleTemplate]="visible"
    [invisibleTemplate]="invisible"
    >
    <ng-template #visible>
        <heatmap
            [xLabels]="heatmapLabels"
            [yLabels]="heatmapLabels"
            [data]="heatmapData"
            [readOnly]="readOnly"
            [heatmapParams]="params.heatmapParams"
            [warnings]="heatmapWarnings"
        >
        </heatmap>
    </ng-template>
    <ng-template #invisible>Please wait...</ng-template>
</lazy-render>
